import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import useLocalStorage from '../hooks/useLocalStorage';

const DefaultLayout: React.FC = () => {
  const navigate = useNavigate();
  const [token, _] = useLocalStorage('token', '');
  const [isPass, setIsPass] = useState<boolean>(false);

  const protectedRoute = () => {
    if (token) {
      navigate('/sornthai');
    }
    setIsPass(true);
  };

  useEffect(protectedRoute, []);

  return (
    <main>
      <div className="mx-auto max-w-screen-2xl px-8">
        {isPass && <Outlet />}
      </div>
    </main>
  );
};

export default DefaultLayout;
