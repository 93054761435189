import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import tw from 'tailwind-styled-components';

interface PaginationProps {
  page: number;
  count: number;
  rowsPerPage: number;
  setPage: (arg: number) => void;
}

const Pagination = ({ page, count, rowsPerPage, setPage }: PaginationProps) => {
  const totalPages = Math.ceil(count / rowsPerPage);

  const getPages = () => {
    const pages = [];
    if (totalPages <= 7) {
      // If there are 7 or fewer pages, show them all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show the first two pages, the last two pages, and the pages around the current page
      pages.push(1);
      if (page > 4) pages.push('...');
      for (
        let i = Math.max(2, page - 2);
        i <= Math.min(totalPages - 1, page + 2);
        i++
      ) {
        pages.push(i);
      }
      if (page < totalPages - 3) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  return (
    <nav aria-label="page navigation">
      <ul className="list-style-none flex justify-center gap-1">
        <li>
          <Button
            type="button"
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            <FaChevronLeft fontSize={20} className="text-primary" />
          </Button>
        </li>
        {getPages().map((pageNum: any, index: number) => (
          <li key={index}>
            {pageNum === '...' ? (
              <span className="mx-2">...</span>
            ) : (
              <A
                href="#"
                onClick={() => setPage(pageNum)}
                className={`${page === pageNum && 'bg-primary text-white'}`}
              >
                {pageNum}
              </A>
            )}
          </li>
        ))}
        <li>
          <Button
            type="button"
            onClick={() => setPage(page + 1)}
            disabled={page === Math.ceil(count / rowsPerPage)}
          >
            <FaChevronRight fontSize={20} className="text-primary" />
          </Button>
        </li>
      </ul>
    </nav>
  );
};

const A = tw.a`relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100`;

const Button = tw.button`relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100`;

export default Pagination;
