import { useEffect, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import Boy from '../../../../images/user/boy.png';
import Girl from '../../../../images/user/girl.png';
import {
  deleteStudentsService,
  getMembersService,
  getStageDataChildrenService,
  getUserDataStagesService,
} from '../../../../services/externalv2_2';
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from 'dayjs';
import tw from 'tailwind-styled-components';
import { useNavigate } from 'react-router-dom';
import { IoIosAddCircle } from 'react-icons/io';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const CustomBar = (props: any) => {
  const { fill, x, y, width, height, value, maxValue } = props;
  const scoreHeight = (value / maxValue) * height;
  return (
    <g>
      <rect x={x} y={y} width={width + 10} height={height} fill="#CDD5FF" />
      <rect
        x={x + 5}
        y={y + (height - scoreHeight)}
        width={width}
        height={scoreHeight}
        fill={fill}
      />
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const { score, avg_sum_score, max_score } = payload[0].payload;
    return (
      <div className="bg-[white] p-2  box-border rounded-lg">
        <div className="flex gap-2 font-bold">
          <p>คะแนน:</p>
          <p className="text-[#5470FF]">{score}</p>
        </div>

        <div className="flex gap-2 font-bold">
          <p>คะแนนเฉลี่ย:</p>
          <p className="text-[#FF0000]">{avg_sum_score}</p>
        </div>

        <div className="flex gap-2 font-bold">
          <p>คะแนนเต็ม:</p>
          <p>{max_score}</p>
        </div>
      </div>
    );
  }

  return null;
};

const DataScope = (props: any) => (
  <div
    key={props.index}
    className={`flex flex-col flex-1 border-[5px] ${props.boderClass} gap-2`}
  >
    <div
      className={`${props.bgClass} py-2 px-8 flex flex-col gap-2 text-center`}
    >
      <p className="text-white text-lg font-semibold">{props.label}</p>
    </div>

    <p className="p-4 gap-3 text-center font-bold text-black">{props.value}</p>
  </div>
);

const DashboardParent: React.FC = () => {
  const navigate = useNavigate();

  const [members, setMembers] = useState<any[]>([]);
  const [member, setMember] = useState<any>({});
  const [stageData, setStageData] = useState<any[]>([]);
  const [userDataStage, setUserDataStage] = useState<any>({ stages: [] });

  const getMembers = () => {
    getMembersService().then((resp) => {
      setMembers(resp.results.members);
      if (resp.results.members.length > 0) setMember(resp.results.members[0]);
    });
  };

  const getStageDataChildren = () => {
    if (member.memberId)
      getStageDataChildrenService(member.memberId).then((resp) => {
        setStageData(resp.results.stages);
      });
  };

  const getUserDataStages = () => {
    if (member.memberId)
      getUserDataStagesService(member.memberId as string).then((data) => {
        setUserDataStage(data.results);
      });
  };

  const handleConfirmDeleteChildren = (childrenid: string) => {
    withReactContent(Swal)
      .fire({
        title: 'คุณแน่ใจไหม?',
        text: 'ว่าต้องการลบข้อมูลที่เลือก',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'ใช่ฉันแน่ใจ',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'bg-primary',
          popup: 'bg-white shadow-md rounded-lg p-4',
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteStudentsService({ studentids: [childrenid] }).then((_) => {
            Swal.fire({
              title: 'ลบแล้ว!',
              text: 'ข้อมูลของคุณถูกลบแล้ว',
              icon: 'success',
              confirmButtonText: 'ตกลง',
            }).then((_) =>
              setMembers(members.filter((m) => m.memberId !== childrenid)),
            );
          });
        }
      });
  };

  const Badge = ({ memberData }: any) => (
    <div className="flex items-center text-white rounded-full px-2 py-1 mx-2">
      <img
        src={memberData.gender === 'male' ? Boy : Girl}
        alt={memberData.name}
        className="w-14 h-14 rounded-full"
      />
      <div
        onClick={() => setMember(memberData)}
        className={`${
          memberData.memberId === member.memberId
            ? 'bg-[#808080] text-white'
            : 'text-[#808080]'
        } px-4 rounded-full w-fit py-1 border border-[#808080] hover:bg-[#808080] duration-500 hover:text-white cursor-pointer`}
      >
        <span className=" whitespace-nowrap">
          {memberData.name} {memberData.surname}
        </span>
      </div>
      <button
        type="button"
        onClick={() => handleConfirmDeleteChildren(memberData.memberId)}
      >
        <MdCancel fontSize={24} className="text-red-500" />
      </button>
    </div>
  );

  useEffect(getMembers, []);

  useEffect(getStageDataChildren, [member]);

  useEffect(getUserDataStages, [member]);

  return (
    <div className="flex flex-col gap-8 rounded-xl border border-stroke bg-white shadow-default p-8">
      <div className="flex justify-between">
        <div className="flex flex-nowrap overflow-x-auto gap-12 xl:gap-0 w-full pr-12">
          {members.map((member) => (
            <Badge
              key={member.memberId}
              memberData={member}
              name={`${member.name} ${member.surname}`}
              gender={member.gender}
            />
          ))}
        </div>

        <button
          onClick={() => navigate('/sornthai/dashboard-parent/create-student')}
          className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF] h-fit self-center"
        >
          <IoIosAddCircle fontSize={24} className="text-green-600" />
        </button>
      </div>

      <div className="flex justify-center">
        <div className="flex gap-4 flex-wrap xl:w-3/4">
          <DataScope
            label="จำนวนเกมที่เล่น"
            bgClass="bg-[#FFCC17]"
            boderClass="border-[#FFCC17]"
            value={member?.countGame || '-'}
          />
          <DataScope
            label="จำนวนครั้งที่เล่น"
            bgClass="bg-[#5470FF]"
            boderClass="border-[#5470FF]"
            value={member?.countPlay || '-'}
          />
          <DataScope
            label="เวลาที่เล่นทั้งหมด"
            bgClass="bg-[#5470FF]"
            boderClass="border-[#5470FF]"
            value={`${
              Math.floor(member.playDuration / 3600) || 0
            } ชั่วโมง ${Math.floor(
              (member.playDuration % 3600) / 60 || 0,
            )} นาที`}
          />
          <DataScope
            label="วันที่เล่นครั้งสุดท้าย"
            bgClass="bg-[#AD0E0E]"
            boderClass="border-[#AD0E0E]"
            value={
              member?.lastPlay
                ? dayjs(member?.lastPlay)
                    .locale('th')
                    .format('D MMMM BBBB')
                : '-'
            }
          />
        </div>
      </div>

      {stageData.length !== 0 && (
        <div className="flex justify-center w-full">
          <div className="w-full xl:w-2/3">
            <ResponsiveContainer width="100%" aspect={2} height={400}>
              <ComposedChart layout="horizontal" data={stageData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="stage_name" />
                <YAxis />

                <Tooltip content={<CustomTooltip />} />

                <Bar
                  dataKey="max_score"
                  barSize={20}
                  fill="#CDD5FF"
                  shape={<CustomBar />}
                >
                  {stageData.map((entry: any, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill="#5470FF"
                      value={entry.score}
                      maxValue={entry.max_score}
                    />
                  ))}
                </Bar>

                <Line type="linear" dataKey="avg_sum_score" stroke="#FF0000" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}

      <div className="container mx-auto p-4 overflow-auto">
        <table className="min-w-full border-collapse border border-black table-auto overflow-scroll">
          <thead className="bg-[#808080] text-white ">
            <tr>
              <Th rowSpan={2}>ด่าน</Th>
              <Th colSpan={3} className="bg-orange-300 text-black font-bold">
                การเล่นครั้งแรก
              </Th>
              <Th colSpan={2} className="bg-blue-400  text-black font-bold">
                การเล่นครั้งสุดท้าย
              </Th>
              <Th colSpan={2}>สระและวรรณยุกต์ที่ควรพัฒนา</Th>
              <Th rowSpan={2} className="w-1/12">
                จำนวนที่เล่นทั้งหมด
              </Th>
            </tr>
            <tr>
              <Th className="bg-orange-300  text-black font-bold">คะแนน</Th>
              <Th className="bg-orange-300  text-black font-bold">
                เวลาที่ใช้
              </Th>
              <Th className="bg-orange-300  text-black font-bold">
                percentile
              </Th>

              <Th className="bg-blue-400  text-black font-bold">คะแนน</Th>
              <Th className="bg-blue-400  text-black font-bold">เวลาที่ใช้</Th>

              <Th className="w-1/6">การเล่นครั้งแรก</Th>
              <Th className="w-1/6">การเล่นสุดท้าย</Th>
            </tr>
            <tr></tr>
          </thead>
          <tbody>
            {userDataStage?.stages.map((stage: any, i: number) => (
              <tr className="text-center" key={i}>
                <Td>
                  <p>{stage.stage_des}</p>
                  <p className="text-xs">คะแนนเต็ม {stage.max_score}</p>
                </Td>

                <Td className="bg-orange-300  text-black">
                  {stage.first_play?.sum_score || '-'}
                </Td>
                <Td className="bg-orange-300  text-black">
                  {stage.first_play?.sum_dur || '-'}
                </Td>
                <Td className="bg-orange-300  text-black">
                  {stage.first_play?.percentile || '-'}
                </Td>

                <Td className="bg-blue-400  text-black">
                  {stage.last_play?.sum_score || '-'}
                </Td>
                <Td className="bg-blue-400  text-black">
                  {stage.last_play?.sum_dur || '-'}
                </Td>

                <Td className="text-wrap">
                  {stage.first_play?.qscores.join(',')}
                </Td>
                <Td className="text-wrap">
                  {stage.last_play?.qscores.join(',')}
                </Td>

                <Td className="text-wrap">{stage.last_play?.play}</Td>
              </tr>
            ))}
            {!userDataStage?.stages.length && (
              <tr className="text-center">
                <Td colSpan={9}>ไม่พบข้อมูล</Td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Th = tw.th`py-2 px-4 font-normal border border-black`;

const Td = tw.td`py-2 px-4 font-normal border`;

export default DashboardParent;
