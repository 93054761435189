import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
const { fetch: originalFetch } = window;

import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import ForgotPassword from './pages/Authentication/ForgotPassword';
import SelectGame from './pages/Dashboard/SelectGame';
import AuthLayout from './layout/AuthLayout';
import DashboardResearcher from './pages/Dashboard/Sornthai/Researcher/DashboardResearcher';
import DashboardParent from './pages/Dashboard/Sornthai/Parents/DashboardParent';
import DefaultLayout from './layout/DefaultLayout';
import SignInInternal from './pages/Authentication/SignInInternal';
import DashboardTeacher from './pages/Dashboard/Sornthai/Teachers/DashboardTeacher';
import UserDataStages from './pages/Dashboard/Sornthai/Teachers/UserDataStages';
import useLocalStorage from './hooks/useLocalStorage';
import CreateUser from './pages/User/CreateUser';
import useUser from './hooks/user';
import Users from './pages/Dashboard/Sornthai/Admin/Users';
import EditUser from './pages/User/EditUser';
import ProtectedRoute from './pages/Authentication/ProtectedRoute';
import Profile from './pages/Dashboard/Sornthai/Profile';
import ResetPassword from './pages/Authentication/ResetPassword';
import GameManual from './pages/GameManual';
import ResearcherUserDataStages from './pages/Dashboard/Sornthai/Researcher/ResearcherUserDataStages';

window.fetch = async (resource, options: any = {}) => {
  let headers: any = {
    'Content-Type': 'application/json',
  };

  const token = JSON.parse(window.localStorage.getItem('token') as string);

  if (!options.headers && token) headers['Authorization'] = `Bearer ${token}`;

  options.headers = {
    ...options.headers,
    ...headers,
  };

  const response = await originalFetch(resource, options);

  // if (!response.ok && response.status === 404) {
  //   // 404 error handling
  //   return Promise.reject(response);
  // }
  return response;
};

function App() {
  const [token] = useLocalStorage('token', '');
  const user = useUser('token');

  const [loading, setLoading] = useState<boolean>(true);
  const [path, setPath] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    switch (user.role) {
      case 0:
      case 1:
      case 2:
        setPath('/sornthai/dashboard-parent');
        break;

      case 3:
        setPath('/sornthai/dashboard-teacher');
        break;

      case 4:
        setPath('/sornthai/dashboard-researcher');
        break;

      case 5:
      case 6:
        setPath('/sornthai/admin/users');
        break;

      default:
        break;
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={token ? `/sornthai` : `/signin`} />}
        />

        <Route path="game-manual" element={<GameManual />} />

        {/* Authentication */}
        <Route path="/" element={<DefaultLayout />}>
          <Route
            path="signin"
            element={
              <>
                <PageTitle title="เข้าสู่ระบบ" />
                <SignIn />
              </>
            }
          />
          <Route
            path="signup"
            element={
              <>
                <PageTitle title="สมัครบัญชีผู้ใช้" />
                <SignUp />
              </>
            }
          />
          <Route
            path="forgot-password"
            element={
              <>
                <PageTitle title="ลืมรหัสผ่าน" />
                <ForgotPassword />
              </>
            }
          />
          <Route
            path="reset-password"
            element={
              <>
                <PageTitle title="ตั้งรหัสใหม่" />
                <ResetPassword />
              </>
            }
          />
          <Route
            path="internal-signin"
            element={
              <>
                <PageTitle title="เข้าสู่ระบบ" />
                <SignInInternal />
              </>
            }
          />
        </Route>

        <Route
          path="/select-game"
          element={
            <>
              <PageTitle title="เลือกเกม" />
              <SelectGame />
            </>
          }
        />

        <Route path="/sornthai" element={<Navigate to={path} />} />

        {/* sornthai app */}
        <Route path="/sornthai" element={<AuthLayout />}>
          <Route
            path="profile"
            element={
              <>
                <PageTitle title="โปรไฟล์" />
                <Profile />
              </>
            }
          />

          {/* Admin, Superadmin */}
          <Route path="admin" element={<ProtectedRoute roles={[4, 5, 6]} />}>
            <Route
              path="users"
              element={
                <>
                  <PageTitle title="ผู้ใช้งาน" />
                  <Users />
                </>
              }
            />

            <Route
              path="create-user"
              element={
                <>
                  <PageTitle title="Create User" />
                  <CreateUser />
                </>
              }
            />

            <Route
              path="edit-user/:userid"
              element={
                <>
                  <PageTitle title="Edit User" />
                  <EditUser />
                </>
              }
            />
          </Route>

          {/* Researcher, Admin, Superadmin */}
          <Route path="" element={<ProtectedRoute roles={[4, 5, 6]} />}>
            <Route
              path="dashboard-researcher"
              element={
                <>
                  <PageTitle title="Dashboard Researcher" />
                  <DashboardResearcher />
                </>
              }
            />

            <Route
              path="dashboard-researcher/user-data-stages/:userid"
              element={
                <>
                  <PageTitle title="Dashboard Researcher" />
                  <ResearcherUserDataStages />
                </>
              }
            />
          </Route>

          {/* Teacher, Researcher, Admin, Superadmin */}
          <Route path="" element={<ProtectedRoute roles={[3, 4, 5, 6]} />}>
            <Route
              path="dashboard-teacher/user-data-stages/:userid"
              element={
                <>
                  <PageTitle title="User data stages" />
                  <UserDataStages />
                </>
              }
            />

            <Route
              path="dashboard-teacher"
              element={
                <>
                  <PageTitle title="Dashboard Teacher" />
                  <DashboardTeacher />
                </>
              }
            />

            <Route
              path="dashboard-teacher/create-student"
              element={
                <>
                  <PageTitle title="Create Student" />
                  <CreateUser />
                </>
              }
            />

            <Route
              path="dashboard-teacher/edit-student/:userid"
              element={
                <>
                  <PageTitle title="Edit User" />
                  <EditUser />
                </>
              }
            />
          </Route>

          {/* Teacher, Researcher, Admin, Superadmin */}
          <Route
            path=""
            element={<ProtectedRoute roles={[0, 1, 2, 3, 4, 5, 6]} />}
          >
            <Route
              path="dashboard-parent"
              element={
                <>
                  <PageTitle title="Dashboard Parent" />
                  <DashboardParent />
                </>
              }
            />

            <Route
              path="dashboard-parent/create-student"
              element={
                <>
                  <PageTitle title="Create Student" />
                  <CreateUser />
                </>
              }
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
