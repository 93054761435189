import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoMdMale, IoMdFemale } from 'react-icons/io';
import { FaEdit } from 'react-icons/fa';
import dayjs from 'dayjs';
import tw from 'tailwind-styled-components';

import { getUserDataStagesService } from '../../../../services/externalv2_2';
import Checkbox from '../../../../components/Checkboxes/Checkbox';

const UserDataStages: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [userDataStage, setUserDataStage] = useState<any>({ stages: [] });

  const getUserDataStages = () => {
    getUserDataStagesService(params.userid as string).then((data) => {
      setUserDataStage(data.results);
    });
  };

  useEffect(getUserDataStages, [params.userid]);

  return (
    <div className="flex flex-col gap-8 rounded-xl border border-stroke bg-white shadow-default p-8">
      <div className="flex flex-col gap-6">
        <div className="flex gap-2">
          <h1 className="text-black text-2xl font-bold">
            ข้อมูลส่วนบุคคล (id:{userDataStage?.user?.username})
          </h1>

          <FaEdit
            className="self-center cursor-pointer hover:text-primary"
            onClick={() =>
              navigate(
                `/sornthai/dashboard-teacher/edit-student/${userDataStage?.user?._id}`,
              )
            }
          />
        </div>

        <form className="grid grid-cols-2 gap-8 xl:px-30">
          <div className="flex flex-col">
            <Label>ชื่อ</Label>
            <Input type="text" value={userDataStage?.user?.name} disabled />
          </div>

          <div className="flex flex-col">
            <Label>นามสกุล</Label>
            <Input type="text" value={userDataStage?.user?.surname} disabled />
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
            <div className="flex flex-col">
              <Label>ชื่อเล่น</Label>
              <Input type="text" value={userDataStage?.user?.nick} disabled />
            </div>

            <div className="flex flex-col">
              <Label>วันเกิด</Label>
              <Input
                type="text"
                value={dayjs(userDataStage?.user?.dob, 'DD-MM-YYYY')
                  .locale('th')
                  .format('D MMMM BBBB')}
                disabled
              />
            </div>
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-4 gap-8">
            <div className="flex flex-col">
              <Label>อายุ</Label>
              <Input
                type="text"
                value={userDataStage?.user?.age_pres}
                disabled
              />
            </div>

            <div className="flex flex-col">
              <Label>เพศ</Label>
              <div className="mt-3"></div>
              {userDataStage?.user?.gend === 'male' && (
                <IoMdMale className="text text-blue-500" fontSize={24} />
              )}
              {userDataStage?.user?.gend === 'female' && (
                <IoMdFemale className="text text-purple-400" fontSize={24} />
              )}
            </div>

            <div className="flex flex-col col-span-2">
              <Label>ความบกพร่องทางการอ่าน</Label>
              <div className="mt-3">
                <Checkbox
                  label="isdylx"
                  isChecked={userDataStage?.user?.isdylx}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <Label>โรงเรียน</Label>
            <Input
              type="text"
              value={userDataStage?.user?.school?.sch_name}
              disabled
            />
          </div>

          <div className="flex flex-col">
            <Label>กลุ่ม</Label>
            <Input
              type="text"
              value={userDataStage?.user?.groupid?.group_name}
              disabled
            />
          </div>

          <div className="flex flex-col">
            <Label>วันที่เล่นเกม ครั้งสุดท้าย</Label>
            <Input
              type="text"
              value={dayjs(userDataStage?.sscore?.timeStamp)
                .locale('th')
                .format('D MMMM BBBB')}
              disabled
            />
          </div>

          <div className="flex flex-col">
            <Label>รวม</Label>
            <Input
              type="text"
              value={`${
                Math.floor(userDataStage?.sscore?.sumDur / 3600) || 0
              } ชั่วโมง ${Math.floor(
                (userDataStage?.sscore?.sumDur % 3600) / 60 || 0,
              )} นาที`}
              disabled
            />
          </div>
        </form>

        <div className="container mx-auto p-4 overflow-auto">
          <table className="min-w-full border-collapse border border-black table-auto overflow-scroll">
            <thead className="bg-[#808080] text-white ">
              <tr>
                <Th rowSpan={2}>ด่าน</Th>
                <Th colSpan={3} className="bg-orange-300 text-black font-bold">
                  การเล่นครั้งแรก
                </Th>
                <Th colSpan={2} className="bg-blue-400  text-black font-bold">
                  การเล่นครั้งสุดท้าย
                </Th>
                <Th colSpan={2}>สระและวรรณยุกต์ที่ควรพัฒนา</Th>
                <Th rowSpan={2} className="w-1/12">
                  จำนวนที่เล่นทั้งหมด
                </Th>
              </tr>
              <tr>
                <Th className="bg-orange-300  text-black font-bold">คะแนน</Th>
                <Th className="bg-orange-300  text-black font-bold">
                  เวลาที่ใช้
                </Th>
                <Th className="bg-orange-300  text-black font-bold">
                  percentile
                </Th>

                <Th className="bg-blue-400  text-black font-bold">คะแนน</Th>
                <Th className="bg-blue-400  text-black font-bold">
                  เวลาที่ใช้
                </Th>

                <Th className="w-1/6">การเล่นครั้งแรก</Th>
                <Th className="w-1/6">การเล่นสุดท้าย</Th>
              </tr>
              <tr></tr>
            </thead>
            <tbody>
              {userDataStage?.stages.map((stage: any, i: number) => (
                <tr className="text-center" key={i}>
                  <Td>
                    <p>{stage.stage_des}</p>
                    <p className="text-xs">คะแนนเต็ม {stage.max_score}</p>
                  </Td>

                  <Td className="bg-orange-300  text-black">
                    {stage.first_play?.sum_score || '-'}
                  </Td>
                  <Td className="bg-orange-300  text-black">
                    {stage.first_play?.sum_dur || '-'}
                  </Td>
                  <Td className="bg-orange-300  text-black">
                    {stage.first_play?.percentile || '-'}
                  </Td>

                  <Td className="bg-blue-400  text-black">
                    {stage.last_play?.sum_score || '-'}
                  </Td>
                  <Td className="bg-blue-400  text-black">
                    {stage.last_play?.sum_dur || '-'}
                  </Td>

                  <Td className="text-wrap">
                    {stage.first_play?.qscores.join(',')}
                  </Td>
                  <Td className="text-wrap">
                    {stage.last_play?.qscores.join(',')}
                  </Td>

                  <Td className="text-wrap">{stage.last_play?.play}</Td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Th = tw.th`py-2 px-4 font-normal border border-black`;

const Td = tw.td`py-2 px-4 font-normal border`;

const Input = tw.input`w-full border-b border-stroke bg-transparent py-2 outline-none`;

const Label = tw.label`block font-bold text-black`;

export default UserDataStages;
