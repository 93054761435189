import React from 'react';
import { Link } from 'react-router-dom';
import { IoGameControllerOutline } from 'react-icons/io5';

const SelectGame: React.FC = () => {
  return (
    <div className="flex justify-center h-screen">
      <div className="my-auto w-full xl:w-2/4 rounded-xl border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="w-full p-4 sm:p-12.5 xl:p-12">
          <div className="mb-9 flex gap-2">
            <h2 className="text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
              เลือกเกม
            </h2>
            <IoGameControllerOutline fontSize={25} className="self-center" />
          </div>

          <div className="flex flex-col xl:flex-row gap-4 justify-around">
            {/* <Link
              to="/alphabet"
              className="group block max-w-xs  rounded-lg p-8 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-primary hover:ring-primary"
            >
              <div className="flex flex-col items-center gap-4">
                <img
                  src="/images/alphabet_logo.png"
                  alt=""
                  className="h-40 w-50"
                />

                <h3 className="text-slate-900 group-hover:text-white text-xl font-semibold">
                </h3>
              </div>
            </Link> */}

            <Link
              to="/sornthai/dashboard-researcher"
              className="group block max-w-xs rounded-lg p-8 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-primary hover:ring-primary"
            >
              <div className="flex flex-col items-center gap-4">
                <img
                  src="/images/sornthai_logo.png"
                  alt=""
                  className="h-40 w-50"
                />
                <h3 className="text-slate-900 group-hover:text-white text-xl font-semibold">
                </h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectGame;
