export const postLoginService = async (body: any) => {
  try {
    const response = await fetch(`/api-sornthai/login`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const postLoginInternalService = async (token: any) => {
  try {
    const response = await fetch(`/api-sornthai/login-internal`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const postRegisterService = async (body: any) => {
  try {
    const response = await fetch(`/api-sornthai/register`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getEducationsService = async () => {
  try {
    const response = await fetch(`/api-sornthai/educations`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getEducationsByTeacherService = async () => {
  try {
    const response = await fetch(`/api-sornthai/educations-by-teacher`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getSchoolsService = async () => {
  try {
    const response = await fetch(`/api-sornthai/schools`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getRolesService = async () => {
  try {
    const response = await fetch(`/api-sornthai/roles`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getStageAverageScoreService = async (educationids: number[]) => {
  try {
    const response = await fetch(
      `/api-sornthai/stage-average-score?educationids=${educationids}`,
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getClearPctStageService = async (educationids: number[]) => {
  try {
    const response = await fetch(
      `/api-sornthai/clear-pct-stage?educationids=${educationids}`,
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getClearPctVowelService = async (educationids: number[]) => {
  try {
    const response = await fetch(
      `/api-sornthai/clear-pct-vowel?educationids=${educationids}&choiceids=cp2_001,cp2_002,cp2_003,cp2_004,cp2_005,cp2_006,cp2_007,cp2_008,cp2_009,cp2_010,cp2_011,cp2_012,cp2_013,cp2_014,cp2_015,cp2_016,cp2_017,cp2_018,cp2_019,cp2_020,cp2_021,cp2_022,cp2_023,cp2_024,cp2_025,cp2_026,cp2_027,cp2_028`,
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getUserListService = async (queryParams: any) => {
  try {
    const response = await fetch(
      `/api-sornthai/user-list?${new URLSearchParams(queryParams).toString()}`,
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getStageDataAnalysisService = async (queryParams: any) => {
  try {
    const response = await fetch(
      `/api-sornthai/stage-data-analysis?${new URLSearchParams(
        queryParams,
      ).toString()}`,
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getRawDataService = async (
  userids?: string[],
  educationids?: number[],
) => {
  try {
    const response = await fetch(
      `/api-sornthai/raw-data?educationids=${educationids}&userids=${userids}`,
    );
    return await response.blob();
  } catch (error) {
    throw error;
  }
};

export const getStageDataStudentsService = async (queryParams: any) => {
  try {
    const response = await fetch(
      `/api-sornthai/stage-data-students?${new URLSearchParams(
        queryParams,
      ).toString()}`,
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getGroupsService = async () => {
  try {
    const response = await fetch(`/api-sornthai/groups`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const postGroupService = async (body: { group_name: string }) => {
  try {
    const response = await fetch(`/api-sornthai/group`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const patchGroupService = async (
  id: string,
  body: { group_name: string },
) => {
  try {
    const response = await fetch(`/api-sornthai/group/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteGroupService = async (id: string) => {
  try {
    const response = await fetch(`/api-sornthai/group/${id}`, {
      method: 'DELETE',
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getUserDataStagesService = async (id: string) => {
  try {
    const response = await fetch(`/api-sornthai/stage-data-student/${id}`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteStudentsService = async (body: { studentids: string[] }) => {
  try {
    const response = await fetch(`/api-sornthai/students`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getStageScoresService = async (queryParams: any) => {
  try {
    const response = await fetch(
      `/api-sornthai/stage-scores?${new URLSearchParams(
        queryParams,
      ).toString()}`,
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getMembersService = async () => {
  try {
    const response = await fetch(`/api-sornthai/members`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getStageDataChildrenService = async (userid: string) => {
  try {
    const response = await fetch(`/api-sornthai/stage-data-children/${userid}`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

// Admin
export const getUsersService = async (queryParams: any) => {
  try {
    const response = await fetch(
      `/api-sornthai/users?${new URLSearchParams(queryParams).toString()}`,
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getUserService = async (userid: string) => {
  try {
    const response = await fetch(`/api-sornthai/users/${userid}`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getUserByMeService = async () => {
  try {
    const response = await fetch(`/api-sornthai/userByMe`);
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const patchUserService = async (userid: string, body: any) => {
  try {
    const response = await fetch(`/api-sornthai/users/${userid}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const resetPasswordService = async (password: string, token: string) => {
  try {
    const response = await fetch('/api-sornthai/reset-password', {
      method: 'POST',
      body: JSON.stringify({ password }),
      headers: { Authorization: `Bearer ${token}` },
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};
