import { useCallback, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  getEducationsService,
  getStageAverageScoreService,
  getClearPctStageService,
  getClearPctVowelService,
  getRawDataService,
  getUserListService,
} from '../../../../services/externalv2_2';
import CheckboxFive from '../../../../components/Checkboxes/CheckboxFive';
import tw from 'tailwind-styled-components';
import dayjs from 'dayjs';
import { BiCheckboxChecked } from 'react-icons/bi';
import { TbSettingsCog } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import { useForm } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <g>
      <text
        x={x}
        y={y}
        fill="#ffffff"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={12}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

const DashboardResearcher: React.FC = () => {
  const navigate = useNavigate();

  const [educations, setEducations] = useState<any[]>([]);
  const [stageAverageScore, setStageAverageScore] = useState<any>({
    playerCount: 0,
    sscores: [],
  });
  const [clearPctScore, setClearPctScore] = useState<any>({
    playerCount: 0,
    sscores: [],
  });
  const [clearPctVowel, setClearPctVowel] = useState<any>({
    playerCount: 0,
    choices: [],
  });
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, _] = useState<number>(10);
  const [users, setUsers] = useState<any>({
    users: [],
    count: 0,
  });

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      search: '',
    },
  });

  const onSubmitHandler = useCallback(() => {
    const values = getValues();

    getUserListService({ size: rowsPerPage, page, role: 1, ...values }).then(
      (data) => {
        setUsers(data.results);
      },
    );
  }, [getValues]);

  const getAgeInThaiFormat = (birthDate: string) => {
    const birth = dayjs(birthDate, 'DD-MM-YYYY');
    const current = dayjs();

    const years = current.diff(birth, 'year');
    birth.add(years, 'years'); // add the number of years to the birth date to calculate months

    const months = current.diff(birth, 'months') % 12;

    return `${years} ปี ${months} เดือน`;
  };

  const [eduRegs, setEduRegs] = useState<any[]>([]);

  const handleCheckboxChange = (e: React.FormEvent<HTMLInputElement>) => {
    const eduid = Number(e.currentTarget.value);
    const dataEduRegs = [...eduRegs];
    if (eduid === -1) {
      setEduRegs([]);
      return;
    } else {
      const index = dataEduRegs.indexOf(0);
      if (index !== -1) dataEduRegs.splice(index, 1);
    }

    const indexToRemove = dataEduRegs.findIndex((el) => el === eduid);
    if (indexToRemove !== -1) {
      // Remove the element using splice
      dataEduRegs.splice(indexToRemove, 1);
    } else {
      dataEduRegs.push(eduid);
    }
    setEduRegs([...dataEduRegs]);
  };

  const getEducations = () => {
    getEducationsService().then((data) => {
      setEducations(data.results.educations);
    });
  };

  const getstageAverageScore = () => {
    getStageAverageScoreService(eduRegs).then((data) => {
      setStageAverageScore(data.results);
    });
  };

  const getClearPctStage = () => {
    getClearPctStageService(eduRegs).then((data) => {
      setClearPctScore(data.results);
    });
  };

  const getClearPctVowel = () => {
    getClearPctVowelService(eduRegs).then((data) => {
      setClearPctVowel(data.results);
    });
  };

  const getUsers = () => {
    getUserListService({ size: rowsPerPage, page, role: 1 }).then((data) => {
      setUsers(data.results);
    });
  };

  const handleDowloadRawData = () => {
    getRawDataService([], eduRegs).then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = `scores-${new Date().getTime()}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  };

  useEffect(getEducations, []);

  useEffect(getstageAverageScore, [eduRegs]);

  useEffect(getClearPctStage, [eduRegs]);

  useEffect(getClearPctVowel, [eduRegs]);

  useEffect(getUsers, [page]);

  return (
    <div className="flex flex-col gap-6 rounded-xl border border-stroke bg-white shadow-default p-8">
      <div className="flex justify-between">
        <div className="text-black text-2xl dark:text-white font-bold">
          กราฟและข้อมูล
        </div>

        <button
          type="button"
          onClick={handleDowloadRawData}
          className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF]"
        >
          ข้อมูลดิบ
        </button>
      </div>

      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-3">
          <h6 className="font-bold text-xl">
            การฟิลเตอร์ข้อมูล (ระดับการศึกษา)
          </h6>
          <div className="flex gap-4 text-lg">
            <CheckboxFive
              label="ทุกคน"
              id={-1}
              isChecked={!eduRegs.length}
              onChange={handleCheckboxChange}
            />
            <CheckboxFive
              label={educations[1]?.name_th}
              id={educations[1]?.educationid}
              isChecked={eduRegs.includes(educations[1]?.educationid)}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="flex flex-wrap gap-4 text-lg">
            {educations.slice(2, 7).map((t) => (
              <CheckboxFive
                label={t.name_th}
                id={t.educationid}
                isChecked={eduRegs.includes(t.educationid)}
                onChange={handleCheckboxChange}
              />
            ))}
          </div>
          <div className="flex flex-wrap gap-4 text-lg">
            {educations.slice(7, 12).map((t) => (
              <CheckboxFive
                label={t.name_th}
                id={t.educationid}
                isChecked={eduRegs.includes(t.educationid)}
                onChange={handleCheckboxChange}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div>
            <h6 className="font-bold text-xl">
              1. กราฟและข้อมูลแสดงคะแนนเฉลี่ยของผู้เล่นในแต่ละด่าน
            </h6>

            <div className="font-normal text-lg">
              จำนวนผู้เล่นทั้งหมด: {stageAverageScore.playerCount} คน
            </div>
          </div>

          <div className="flex flex-col xl:flex-row justify-between gap-16">
            <div className="w-full xl:w-1/2 overflow-x-auto">
              <table className="table-fixed min-w-full border border-neutral-200 text-center text-sm font-light text-surface dark:border-white/10 dark:text-white">
                <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
                  <tr className="text-lg">
                    <th
                      scope="col"
                      className="border-e border-neutral-200 px-6 py-4 dark:border-white/10"
                    >
                      ด่าน
                    </th>
                    <th
                      scope="col"
                      className="border-e border-neutral-200 px-6 py-4 dark:border-white/10"
                    >
                      <p>จำนวน</p>
                      <p>ผู้ที่เล่นจบ (คน)</p>
                    </th>

                    <th
                      scope="col"
                      className="border-e border-neutral-200 px-6 py-4 dark:border-white/10"
                    >
                      คะแนนเฉลี่ย
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {stageAverageScore.sscores.map(
                    (sscore: any, index: number) => (
                      <tr
                        key={index}
                        className="border-b border-neutral-200 dark:border-white/10 text-lg"
                      >
                        <td className="text-wrap whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium dark:border-white/10">
                          {sscore.stageName}
                        </td>
                        <td className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 dark:border-white/10">
                          {sscore.playerCount}
                        </td>
                        <td className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 dark:border-white/10">
                          {sscore.avgScore}
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>

            <div className="w-full xl:w-1/2">
              <h6 className="font-bold text-xl">คะแนนเฉลี่ย</h6>

              <ResponsiveContainer height={650} className="w-full">
                <BarChart layout="vertical" data={stageAverageScore.sscores}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis
                    dataKey="stageName"
                    type="category"
                    y={0}
                    vertOriginY={0}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="avgScore"
                    className="fill-primary"
                    barSize={20}
                    stackId="s"
                  />

                  <Bar
                    dataKey="maxScore"
                    className="fill-secondary"
                    barSize={20}
                    stackId="s"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <h6 className="font-bold text-xl">
              2. กราฟแสดงความยาก/ง่ายในแต่ละด่าน
            </h6>

            <div className="font-normal text-lg">
              จำนวนผู้เล่นทั้งหมด: {clearPctScore.playerCount} คน
            </div>
          </div>

          <div className="flex flex-col xl:flex-row justify-between gap-16">
            <div className="w-full xl:w-1/2 overflow-x-auto">
              <table className="table-fixed min-w-full border border-neutral-200 text-center text-sm font-light text-surface dark:border-white/10 dark:text-white">
                <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
                  <tr className="text-lg">
                    <th
                      scope="col"
                      className="border-e border-neutral-200 px-6 py-4 dark:border-white/10"
                    >
                      ด่าน
                    </th>
                    <th
                      scope="col"
                      className="border-e border-neutral-200 px-6 py-4 dark:border-white/10"
                    >
                      <p>จำนวนผู้ที่เล่นจบ</p>
                      <p>ในครั้งแรก (คน)</p>
                    </th>

                    <th
                      scope="col"
                      className="border-e border-neutral-200 px-6 py-4 dark:border-white/10"
                    >
                      ค่าความยากง่าย (%)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {clearPctScore.sscores.map((sscore: any, index: number) => (
                    <tr
                      key={index}
                      className="border-b border-neutral-200 dark:border-white/10  text-lg"
                    >
                      <td className="text-wrap whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium dark:border-white/10">
                        {sscore.stageName}
                      </td>
                      <td className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 dark:border-white/10">
                        {sscore.playerCount}
                      </td>
                      <td className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 dark:border-white/10">
                        {sscore.clearPct}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="w-full xl:w-1/2">
              <h6 className="font-bold text-xl">ค่าความยากง่าย (%)</h6>

              <ResponsiveContainer height={650} className="w-full">
                <BarChart layout="vertical" data={clearPctScore.sscores}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis
                    dataKey="stageName"
                    type="category"
                    y={0}
                    vertOriginY={0}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="clearPct"
                    className="fill-primary"
                    barSize={20}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <h6 className="font-bold text-xl">
              3. กราฟแสดงความยาก/ง่ายในแต่ละตัวแปร
            </h6>

            <div className="flex gap-4">
              <div className="font-normal text-lg self-center">
                จำนวนผู้เล่นทั้งหมด: {clearPctVowel.playerCount} คน
              </div>

              <div className="flex gap-3">
                <div className="flex h-3 w-3 items-center justify-center rounded-full bg-[#2BA1A9] self-center"></div>
                <p className="text-lg">คนที่ตอบถูก</p>
              </div>

              <div className="flex gap-3">
                <div className="flex h-3 w-3 items-center justify-center rounded-full bg-[#FF0000] self-center"></div>
                <p className="text-lg">คนที่ตอบผิด</p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 xl:grid-cols-4">
            {clearPctVowel.choices.map((question: any, index: number) => (
              <PieChart width={200} height={200} key={index}>
                <Pie
                  data={question.data}
                  innerRadius={50}
                  outerRadius={80}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  <Label
                    value={question.choice}
                    position="center"
                    className="label-top text-2xl"
                  />
                  <Tooltip />
                </Pie>
              </PieChart>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <h6 className="font-bold text-xl">4. ข้อมูลผู้เล่นรายบุคคล</h6>
          </div>

          <div className="flex flex-col px-4">
            <div className="flex gap-4">
              <Input
                type="text"
                className='xl:w-1/2'
                placeholder="ระบุคำค้นหา ..."
                {...register('search')}
              />

              <div className="self-center">
                <button
                  type="button"
                  onClick={handleSubmit(onSubmitHandler)}
                  className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF]"
                >
                  <FaSearch fontSize={24} />
                </button>
              </div>
            </div>
          </div>

          <div className="container mx-auto p-4 overflow-auto">
            <table className="min-w-full border-collapse border border-black table-auto overflow-scroll">
              <thead className="bg-[#808080] text-white  font-normal">
                <tr>
                  <Th>ID</Th>
                  <Th>ชื่อจริง</Th>
                  <Th>นามสกุล</Th>
                  <Th>เพศ</Th>
                  <Th>อายุ</Th>
                  <Th>ระดับการศึกษา ปัจจุบัน</Th>
                  <Th>โรงเรียน</Th>
                  <Th>ความบกพร่อง ทางการอ่าน</Th>
                  <Th></Th>
                </tr>
              </thead>
              <tbody>
                {users.users.map((user: any, i: number) => (
                  <tr className="text-center" key={i}>
                    <Td>{user.username}</Td>
                    <Td>{user.name}</Td>
                    <Td>{user.surname}</Td>
                    <Td>{user.gend === 'male' ? 'ชาย' : 'หญิง'}</Td>
                    <Td>{getAgeInThaiFormat(user.dob)}</Td>
                    <Td>{user?.eduReg?.name_th}</Td>
                    <Td>{user?.school?.sch_name}</Td>
                    <Td>
                      {user.isdylx && (
                        <div className="flex justify-center">
                          <BiCheckboxChecked
                            fontSize={26}
                            className="text-primary text-center"
                          />
                        </div>
                      )}
                    </Td>
                    <Td className="text-center">
                      <button
                        type="button"
                        className="border rounded-full p-1"
                        onClick={() =>
                          navigate(
                            `/sornthai/dashboard-researcher/user-data-stages/${user._id}`,
                          )
                        }
                      >
                        <TbSettingsCog className="self-center" />
                      </button>
                    </Td>
                  </tr>
                ))}
              </tbody>
              {!users.users.length && (
                <tr className="text-center">
                  <Td colSpan={9}>ไม่พบข้อมูล</Td>
                </tr>
              )}
            </table>

            <div className="flex justify-center mt-2">
              <Pagination
                page={page}
                count={users.count}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Th = tw.th`py-2 px-4 font-normal border border-black`;
const Td = tw.td`py-2 px-4 font-normal border`;
const Input = tw.input`w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark`;

export default DashboardResearcher;
