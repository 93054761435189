type ITEMS = {
  id: string | number;
  label: string;
};

export const userRoles: ITEMS[] = [
  {
    id: 1,
    label: 'ผู้ใช้งานทั่วไป / นักเรียน',
  },
  {
    id: 2,
    label: 'ผูู้ปกครอง',
  },
  {
    id: 3,
    label: 'ครู',
  },
];
