import { useEffect } from 'react';
import Loader from '../../common/Loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';
import { postLoginInternalService } from '../../services/externalv2_2';

const SignInInternal: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [_, setToken] = useLocalStorage('token', '');

  const handleSignIn = () => {
    const token = searchParams.get('token');

    if (!token) {
      setToken('');
      setTimeout(() => {
        navigate('/signin');
      }, 1000);
      return;
    }

    postLoginInternalService(searchParams.get('token'))
      .then((json) => {
        if (json.resultCode === '20000') {
          setToken(json.results.token);
          setTimeout(() => {
            navigate(searchParams.get('path') as string);
          }, 1000);
        } else {
          setToken('');
          setTimeout(() => {
            navigate('/signin');
          }, 1000);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(handleSignIn, [searchParams]);

  return <Loader />;
};

export default SignInInternal;
