import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MdOutlineMailOutline,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { RiAccountCircleLine } from 'react-icons/ri';
import { Thai } from 'flatpickr/dist/l10n/th';
import flatpickr from 'flatpickr';

import Checkbox from '../../components/Checkboxes/Checkbox';
import { userRoles } from '../../config/app';
import { postRegisterService } from '../../services/externalv2_2';

const SignUp: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCfPassword, setShowCfPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleClickShowCfPassword = () => setShowCfPassword((show) => !show);
  const handleMouseDownCfPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      reTypePassword: '',
      email: '',
      role: null,
      name: '',
      surname: '',
      nick: '',
      dob: '',
      gend: 'male',
      isdylx: false,
    },
  });

  const gendWatch = watch('gend');
  const isdylxWatch = watch('isdylx');

  const handleGenderChanged = (e: React.FormEvent<HTMLInputElement>) => {
    setValue('gend', e.currentTarget.value);
  };

  const handleIsdylxChanged = (e: React.FormEvent<HTMLInputElement>) => {
    setValue('isdylx', !isdylxWatch);
  };

  const onSubmitHandler = useCallback(() => {
    const values = getValues();

    setIsLoading(true);

    postRegisterService({
      username: values.username,
      password: values.password,
      email: values.email,
      role: Number(values.role),
      name: values.name,
      surname: values.surname,
      nick: values.nick,
      dob: values.dob,
      gend: values.gend,
      isdylx: values.isdylx,
    })
      .then((json) => {
        setIsLoading(false);
        if (json.resultCode === '20100') {
          reset();
          window.scrollTo(0, 0);
          setIsSuccess(true);
        } else {
          setError('username', {
            type: json.resultCode,
            message: json.message,
          });
        }
      })
      .catch((error) => console.error(error));
  }, [getValues]);

  useEffect(() => {
    // Init flatpickr
    flatpickr('.form-datepicker', {
      locale: Thai,
      mode: 'single',
      altFormat: 'M j, Y',
      dateFormat: 'd-m-Y',
      prevArrow:
        '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    });
  }, []);

  return (
    <div className="flex justify-center">
      <div className="rounded-xl border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark my-12 xl:w-1/2">
        <div className="flex flex-wrap items-center">
          <div className="w-full">
            <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
              <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                สมัครบัญชีผู้ใช้งาน Sornthai
              </h2>

              {isSuccess && (
                <div className="flex w-full border-l-6 border-[#34D399] bg-[#34D399] bg-opacity-[15%] px-4 py-3 shadow-md dark:bg-[#1B1B24] dark:bg-opacity-30 mb-4">
                  <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-[#34D399]">
                    <svg
                      width="16"
                      height="12"
                      viewBox="0 0 16 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.2984 0.826822L15.2868 0.811827L15.2741 0.797751C14.9173 0.401867 14.3238 0.400754 13.9657 0.794406L5.91888 9.45376L2.05667 5.2868C1.69856 4.89287 1.10487 4.89389 0.747996 5.28987C0.417335 5.65675 0.417335 6.22337 0.747996 6.59026L0.747959 6.59029L0.752701 6.59541L4.86742 11.0348C5.14445 11.3405 5.52858 11.5 5.89581 11.5C6.29242 11.5 6.65178 11.3355 6.92401 11.035L15.2162 2.11161C15.5833 1.74452 15.576 1.18615 15.2984 0.826822Z"
                        fill="white"
                        stroke="white"
                      ></path>
                    </svg>
                  </div>
                  <div className="w-full">
                    <h5 className="mb-0 text-lg font-semibold text-black dark:text-[#34D399] ">
                      สมัครบัญชีผู้ใช้งานสำเร็จ
                    </h5>
                    <p className="text-base leading-relaxed text-body">
                      คุณสามารถลิงก์ไปยังหน้า
                      <Link to="/signin" className="ml-1 text-primary">
                        เข้าสู่ระบบ
                      </Link>
                    </p>
                  </div>
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    บัญชีผู้ใช้งาน
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="ระบุบัญชีผู้ใช้งาน"
                      className={`${
                        !!errors['username'] && 'border-meta-1'
                      } w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                      {...register('username', {
                        required: 'กรุณาระบุบัญชีผู้ใช้งาน',
                      })}
                    />

                    <span className="absolute right-4 top-4">
                      <RiAccountCircleLine
                        fontSize={25}
                        className="opacity-50"
                      />
                    </span>
                  </div>
                  {errors.username && (
                    <p className="text-danger">
                      {errors?.username?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    รหัสผ่าน
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="ระบุรหัสผ่าน"
                      className={`${
                        !!errors['password'] && 'border-meta-1'
                      } w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                      {...register('password', {
                        required: 'กรุณาระบุรหัสผ่าน',
                      })}
                    />

                    <span className="absolute right-4 top-4">
                      <button
                        type="button"
                        className="text-[25px]"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                      </button>
                    </span>
                  </div>
                  {errors.password && (
                    <p className="text-danger">
                      {errors?.password?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    ยืนยันรหัสผ่าน
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type={showCfPassword ? 'text' : 'password'}
                      placeholder="ระบุยืนยันรหัสผ่าน"
                      className={`${
                        !!errors['reTypePassword'] && 'border-meta-1'
                      } w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                      {...register('reTypePassword', {
                        required: 'กรุณาระบุยืนยันรหัสผ่าน',
                        validate: (val: string) => {
                          if (watch('password') != val) {
                            return 'รหัสผ่านของคุณไม่ตรงกัน';
                          }
                        },
                      })}
                    />

                    <span className="absolute right-4 top-4">
                      <button
                        type="button"
                        className="text-[25px]"
                        onClick={handleClickShowCfPassword}
                        onMouseDown={handleMouseDownCfPassword}
                      >
                        {showCfPassword ? (
                          <MdVisibilityOff />
                        ) : (
                          <MdVisibility />
                        )}
                      </button>
                    </span>
                  </div>
                  {errors.reTypePassword && (
                    <p className="text-danger">
                      {errors?.reTypePassword?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    อีเมล
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      placeholder="ระบุอีเมล"
                      className={`${
                        !!errors['email'] && 'border-meta-1'
                      } w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                      {...register('email', {
                        required: 'กรุณาระบุอีเมล',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'รูปแบบอีเมลไม่ถูกต้อง',
                        },
                      })}
                    />

                    <span className="absolute right-4 top-4">
                      <MdOutlineMailOutline
                        fontSize={25}
                        className="opacity-50"
                      />
                    </span>
                  </div>
                  {errors.email && (
                    <p className="text-danger">
                      {errors?.email?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    ชื่อจริง
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="ระบุชื่อจริง"
                    className={`${
                      !!errors['name'] && 'border-meta-1'
                    } w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                    {...register('name', {
                      required: 'กรุณาระบุชื่อจริง',
                    })}
                  />
                  {errors.name && (
                    <p className="text-danger">
                      {errors?.name?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    นามสกุล
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="ระบุนามสกุล"
                    className={`${
                      !!errors['reTypePassword'] && 'border-meta-1'
                    } w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                    {...register('surname', {
                      required: 'กรุณาระบุนามสกุล',
                    })}
                  />
                  {errors.surname && (
                    <p className="text-danger">
                      {errors?.surname?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    ชื่อเล่น
                  </label>
                  <input
                    type="text"
                    placeholder="ระบุชื่อเล่น"
                    className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    {...register('nick')}
                  />
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    วันเกิด
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="relative">
                    <input
                      className={`${
                        !!errors['dob'] && 'border-meta-1'
                      } form-datepicker w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary`}
                      placeholder="เดือน วัน, ปี"
                      data-class="flatpickr-right"
                      {...register('dob', {
                        required: 'กรุณาระบุวันเกิด',
                      })}
                    />

                    <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.7504 2.9812H14.2879V2.36245C14.2879 2.02495 14.0066 1.71558 13.641 1.71558C13.2754 1.71558 12.9941 1.99683 12.9941 2.36245V2.9812H4.97852V2.36245C4.97852 2.02495 4.69727 1.71558 4.33164 1.71558C3.96602 1.71558 3.68477 1.99683 3.68477 2.36245V2.9812H2.25039C1.29414 2.9812 0.478516 3.7687 0.478516 4.75308V14.5406C0.478516 15.4968 1.26602 16.3125 2.25039 16.3125H15.7504C16.7066 16.3125 17.5223 15.525 17.5223 14.5406V4.72495C17.5223 3.7687 16.7066 2.9812 15.7504 2.9812ZM1.77227 8.21245H4.16289V10.9968H1.77227V8.21245ZM5.42852 8.21245H8.38164V10.9968H5.42852V8.21245ZM8.38164 12.2625V15.0187H5.42852V12.2625H8.38164V12.2625ZM9.64727 12.2625H12.6004V15.0187H9.64727V12.2625ZM9.64727 10.9968V8.21245H12.6004V10.9968H9.64727ZM13.8379 8.21245H16.2285V10.9968H13.8379V8.21245ZM2.25039 4.24683H3.71289V4.83745C3.71289 5.17495 3.99414 5.48433 4.35977 5.48433C4.72539 5.48433 5.00664 5.20308 5.00664 4.83745V4.24683H13.0504V4.83745C13.0504 5.17495 13.3316 5.48433 13.6973 5.48433C14.0629 5.48433 14.3441 5.20308 14.3441 4.83745V4.24683H15.7504C16.0316 4.24683 16.2566 4.47183 16.2566 4.75308V6.94683H1.77227V4.75308C1.77227 4.47183 1.96914 4.24683 2.25039 4.24683ZM1.77227 14.5125V12.2343H4.16289V14.9906H2.25039C1.96914 15.0187 1.77227 14.7937 1.77227 14.5125ZM15.7504 15.0187H13.8379V12.2625H16.2285V14.5406C16.2566 14.7937 16.0316 15.0187 15.7504 15.0187Z"
                          fill="#64748B"
                        />
                      </svg>
                    </div>
                  </div>
                  {errors.dob && (
                    <p className="text-danger">
                      {errors?.dob?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    บทบาท
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="relative z-20 bg-white dark:bg-form-input">
                    <select
                      className={`${
                        !!errors['role'] && 'border-meta-1'
                      } relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-6 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input`}
                      {...register('role', { required: 'กรุณาเลือกบทบาท' })}
                    >
                      <option value="" disabled selected>
                        เลือกบทบาท
                      </option>
                      {userRoles.map((item: any) => (
                        <option
                          key={item.id}
                          value={item.id}
                          className="text-body dark:text-bodydark"
                        >
                          {item.label}
                        </option>
                      ))}
                    </select>

                    <span className="absolute top-1/2 right-4 z-10 -translate-y-1/2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                            fill="#637381"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                  {errors.role && (
                    <p className="text-danger">
                      {errors?.role?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    เพศ
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="flex gap-4">
                    <Checkbox
                      label="ชาย"
                      id="male"
                      isChecked={gendWatch === 'male'}
                      onChange={handleGenderChanged}
                    />
                    <Checkbox
                      label="หญิง"
                      id="female"
                      isChecked={gendWatch === 'female'}
                      onChange={handleGenderChanged}
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    ความบกพร่องทางการอ่าน
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="flex gap-4">
                    <Checkbox
                      label="มี"
                      id="isdylx"
                      isChecked={isdylxWatch}
                      onChange={handleIsdylxChanged}
                    />
                  </div>
                </div>

                <div className="mb-5">
                  <input
                    type="submit"
                    value="สร้างบัญชีผู้ใช้งาน"
                    className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                    disabled={isLoading}
                  />
                </div>

                <div className="mt-6 text-center">
                  <p>
                    มีบัญชีอยู่แล้ว?{' '}
                    <Link to="/signin" className="text-primary">
                      เข้าสู่ระบบ
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
