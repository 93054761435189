import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaSpinner } from 'react-icons/fa';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { resetPasswordService } from '../../services/externalv2_2';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCfPassword, setShowCfPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleClickShowCfPassword = () => setShowCfPassword((show) => !show);
  const handleMouseDownCfPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      reTypePassword: '',
    },
  });

  const onSubmitHandler = useCallback(() => {
    const values = getValues();

    const token = searchParams.get('token') as string;

    if (!token) {
      setTimeout(() => {
        navigate('/signin');
      }, 1000);
      return;
    }

    setIsLoading(true);

    resetPasswordService(values.password, token)
      .then((_) => {
        reset();
        window.scrollTo(0, 0);
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch((error) => console.error(error));
  }, [getValues]);

  return (
    <div className="flex justify-center">
      <div className="rounded-xl border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark my-12 xl:w-1/2">
        <div className="flex flex-wrap items-center">
          <div className="w-full">
            <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
              {isSuccess && (
                <div className="flex w-full border-l-6 border-[#34D399] bg-[#34D399] bg-opacity-[15%] px-4 py-3 shadow-md dark:bg-[#1B1B24] dark:bg-opacity-30 mb-4">
                  <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-[#34D399]">
                    <svg
                      width="16"
                      height="12"
                      viewBox="0 0 16 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.2984 0.826822L15.2868 0.811827L15.2741 0.797751C14.9173 0.401867 14.3238 0.400754 13.9657 0.794406L5.91888 9.45376L2.05667 5.2868C1.69856 4.89287 1.10487 4.89389 0.747996 5.28987C0.417335 5.65675 0.417335 6.22337 0.747996 6.59026L0.747959 6.59029L0.752701 6.59541L4.86742 11.0348C5.14445 11.3405 5.52858 11.5 5.89581 11.5C6.29242 11.5 6.65178 11.3355 6.92401 11.035L15.2162 2.11161C15.5833 1.74452 15.576 1.18615 15.2984 0.826822Z"
                        fill="white"
                        stroke="white"
                      ></path>
                    </svg>
                  </div>
                  <div className="w-full">
                    <h5 className="mb-0 text-lg font-semibold text-black dark:text-[#34D399] ">
                      ตั้งรหัสใหม่สำเร็จ
                    </h5>
                    <p className="text-base leading-relaxed text-body">
                      คุณสามารถลิงก์ไปยังหน้า
                      <Link to="/signin" className="ml-1 text-primary">
                        เข้าสู่ระบบ
                      </Link>
                    </p>
                  </div>
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    รหัสผ่าน
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="ระบุรหัสผ่าน"
                      className={`${
                        !!errors['password'] && 'border-meta-1'
                      } w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                      {...register('password', {
                        required: 'กรุณาระบุรหัสผ่าน',
                      })}
                    />

                    <span className="absolute right-4 top-4">
                      <button
                        type="button"
                        className="text-[25px]"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                      </button>
                    </span>
                  </div>
                  {errors.password && (
                    <p className="text-danger">
                      {errors?.password?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    ยืนยันรหัสผ่าน
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type={showCfPassword ? 'text' : 'password'}
                      placeholder="ระบุยืนยันรหัสผ่าน"
                      className={`${
                        !!errors['reTypePassword'] && 'border-meta-1'
                      } w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                      {...register('reTypePassword', {
                        required: 'กรุณาระบุยืนยันรหัสผ่าน',
                        validate: (val: string) => {
                          if (watch('password') != val) {
                            return 'รหัสผ่านของคุณไม่ตรงกัน';
                          }
                        },
                      })}
                    />

                    <span className="absolute right-4 top-4">
                      <button
                        type="button"
                        className="text-[25px]"
                        onClick={handleClickShowCfPassword}
                        onMouseDown={handleMouseDownCfPassword}
                      >
                        {showCfPassword ? (
                          <MdVisibilityOff />
                        ) : (
                          <MdVisibility />
                        )}
                      </button>
                    </span>
                  </div>
                  {errors.reTypePassword && (
                    <p className="text-danger">
                      {errors?.reTypePassword?.message as string}
                    </p>
                  )}
                </div>

                <div className="mb-5 relative block">
                  <span
                    className={`absolute inset-y-0 left-1/3 flex items-center pl-8 ${
                      !isLoading && 'hidden'
                    }`}
                  >
                    <FaSpinner className="animate-spin text-white" />
                  </span>
                  <input
                    type="submit"
                    value="ยืนยัน"
                    className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                    disabled={isLoading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
