import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RiAccountCircleLine } from 'react-icons/ri';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useForm } from 'react-hook-form';

import useLocalStorage from '../../hooks/useLocalStorage';
import { postLoginService } from '../../services/externalv2_2';

const SignIn: React.FC = () => {
  const navigate = useNavigate();

  const [_, setToken] = useLocalStorage('token', '');

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors, isLoading },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const onSubmitHandler = useCallback(() => {
    postLoginService(getValues())
      .then((json) => {
        if (json.resultCode === '20000') {
          setToken(json.results.token);
          setTimeout(() => {
            navigate('/select-game');
          }, 1000);
        } else {
          setError('username', {
            type: json.resultCode,
            message: json.message,
          });
          setValue('password', '');
        }
      })
      .catch((error) => console.error(error));
  }, [getValues]);

  return (
    <div className="flex justify-center h-screen">
      <div className="my-auto w-full xl:w-1/3 rounded-xl border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="w-full p-4 sm:p-12.5 xl:p-12">
          <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
            Sornthai
          </h2>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="mb-4">
              <label className="mb-2.5 block font-medium text-black dark:text-white">
                บัญชีผู้ใช้งาน
              </label>
              <div className="relative">
                <input
                  type="text"
                  placeholder="ระบุบัญชีผู้ใช้งาน"
                  className={`${
                    !!errors['username'] && 'border-danger'
                  } w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                  {...register('username', {
                    required: 'กรุณาระบุบัญชีผู้ใช้งาน',
                  })}
                />

                <span className="absolute right-4 top-4">
                  <RiAccountCircleLine fontSize={25} className="opacity-50" />
                </span>
              </div>
              {errors.username && (
                <p className="text-danger">
                  {errors?.username?.message as string}
                </p>
              )}
            </div>

            <div className="mb-6">
              <label className="mb-2.5 block font-medium text-black dark:text-white">
                รหัสผ่าน
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="ระบุรหัสผ่าน"
                  className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  {...register('password', {
                    required: 'กรุณาระบุรหัสผ่าน',
                  })}
                />

                <span className="absolute right-4 top-4">
                  <button
                    type="button"
                    className="text-[25px]"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                  </button>
                </span>
              </div>
            </div>

            <div className="mb-5">
              <input
                type="submit"
                value="เข้าสู่ระบบ"
                disabled={isLoading}
                className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
              />
            </div>

            <div className="flex gap-2 mt-6 justify-center">
              <p>
                <Link to="/signup" className="text-primary">
                  สมัครบัญชี
                </Link>
              </p>
              <div className="border"></div>
              <p>
                <Link to="/forgot-password" className="text-primary">
                  ลืมรหัสผ่าน
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
