import React, { useCallback, useEffect, useState } from 'react';
import {
  getEducationsService,
  getRolesService,
  getSchoolsService,
  getUserByMeService,
  patchUserService,
} from '../../../services/externalv2_2';
import { RiAccountCircleLine, RiLockPasswordFill } from 'react-icons/ri';
import Select from 'react-select';

import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import Checkbox from '../../../components/Checkboxes/Checkbox';
import { MdOutlineMailOutline } from 'react-icons/md';
import useUser from '../../../hooks/user';

const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    border: '2px solid #e2e8f0', // Tailwind CSS border color
    borderRadius: '0.375rem', // Tailwind CSS border radius
    padding: '0.3rem', // Tailwind CSS padding
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '0.375rem', // Tailwind CSS border radius
  }),
};

const Profile = () => {
  const params = useParams();
  const navigate = useNavigate();
  const user = useUser('token');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [educations, setEducations] = useState<any[]>([]);
  const [schools, setShools] = useState<any[]>([]);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      reTypePassword: '',
      email: '',
      role: '',
      name: '',
      surname: '',
      nick: '',
      dob: '',
      teacherid: '',
      parentid: '',
      gend: 'male',
      edu_reg: null,
      schid: null,
      groupid: null,
      isdylx: false,
    },
  });

  const gendWatch = watch('gend');
  const isdylxWatch = watch('isdylx');
  const roleWatch = watch('role');

  const getEducations = () => {
    getEducationsService().then((data) => {
      setEducations(data.results.educations);
    });
  };

  const getSchools = () => {
    getSchoolsService().then((data) => {
      setShools(data.results.schools);
    });
  };

  const handleGenderChanged = (e: React.FormEvent<HTMLInputElement>) => {
    setValue('gend', e.currentTarget.value);
  };

  const handleIsdylxChanged = (e: React.FormEvent<HTMLInputElement>) => {
    setValue('isdylx', !isdylxWatch);
  };

  const getUser = () => {
    getUserByMeService().then((data) => {
      const {
        username,
        email,
        role,
        name,
        surname,
        gend,
        dob,
        nick,
        isdylx,
        edu_reg,
        schid,
        groupid,
      } = data.results.user;

      setValue('username', username);
      setValue('email', email);
      setValue('role', role.toString());
      setValue('name', name);
      setValue('surname', surname);
      setValue('gend', gend);
      setValue('dob', dob);
      setValue('nick', nick);
      setValue('isdylx', isdylx);
      setValue('edu_reg', edu_reg);
      setValue('schid', schid);
      setValue('groupid', groupid);
    });
  };

  const onSubmitHandler = useCallback(() => {
    const values = getValues();

    setIsLoading(true);

    patchUserService(user._id as string, {
      //   username: values.username,
      password: values.password || undefined,
      email: values.email,
      role: Number(values.role),
      name: values.name,
      surname: values.surname,
      nick: values.nick,
      dob: values.dob,
      gend: values.gend,
      isdylx: values.isdylx,
      teacherid: values.teacherid || undefined,
      parentid: values.parentid || undefined,
      edu_reg: values.edu_reg ? Number(values.edu_reg) : undefined,
      schid: values.schid || undefined,
      groupid: values.groupid || undefined,
    })
      .then((json) => {
        setIsLoading(false);
        if (json.resultCode === '20000') {
          window.scrollTo(0, 0);
          setIsSuccess(true);
        } else {
          setError('username', {
            type: json.resultCode,
            message: json.message,
          });
        }
      })
      .catch((error) => console.error(error));
  }, [getValues]);

  useEffect(getEducations, []);
  useEffect(getSchools, []);
  useEffect(getUser, [params.userid]);

  return (
    <div className="flex">
      <div className="rounded-xl border border-stroke bg-white shadow-default w-full">
        <div className="flex flex-wrap items-center justify-center">
          <div className="p-4 sm:p-12.5 xl:p-17.5  w-full">
            <h2 className="mb-9 text-2xl font-bold text-black sm:text-title-xl2">
              แก้ไขบัญชีผู้ใช้งาน
            </h2>
            {isSuccess && (
              <div className="mb-8 flex w-full border-l-6 border-[#34D399] bg-[#34D399] bg-opacity-[15%] px-4 py-3 shadow-md dark:bg-[#1B1B24] dark:bg-opacity-30 ">
                <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-[#34D399]">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2984 0.826822L15.2868 0.811827L15.2741 0.797751C14.9173 0.401867 14.3238 0.400754 13.9657 0.794406L5.91888 9.45376L2.05667 5.2868C1.69856 4.89287 1.10487 4.89389 0.747996 5.28987C0.417335 5.65675 0.417335 6.22337 0.747996 6.59026L0.747959 6.59029L0.752701 6.59541L4.86742 11.0348C5.14445 11.3405 5.52858 11.5 5.89581 11.5C6.29242 11.5 6.65178 11.3355 6.92401 11.035L15.2162 2.11161C15.5833 1.74452 15.576 1.18615 15.2984 0.826822Z"
                      fill="white"
                      stroke="white"
                    ></path>
                  </svg>
                </div>
                <div className="w-full">
                  <h5 className="mb-0 text-lg font-semibold text-black dark:text-[#34D399] ">
                    แก้ไขบัญชีผู้ใช้งานสำเร็จ
                  </h5>
                  <p className="text-base leading-relaxed text-body">
                    กลับสู่
                    <Link
                      to="javascript:void(0)"
                      onClick={() => navigate(-1)}
                      className="ml-1 text-primary"
                    >
                      หน้าหลัก
                    </Link>
                  </p>
                </div>
              </div>
            )}

            <form
              onSubmit={handleSubmit(onSubmitHandler)}
              className="grid grid-cols-1 gap-4 xl:grid-cols-2"
            >
              <div className="">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  บัญชีผู้ใช้งาน
                </label>
                <div className="relative">
                  <Input
                    type="text"
                    placeholder="ระบุบัญชีผู้ใช้งาน"
                    disabled
                    className={`${!!errors['username'] && 'border-meta-1'}`}
                    {...register('username', {
                      disabled: true,
                    })}
                  />

                  <span className="absolute right-4 top-4">
                    <RiAccountCircleLine fontSize={25} className="opacity-50" />
                  </span>
                </div>
                {errors.username && (
                  <p className="text-danger">
                    {errors?.username?.message as string}
                  </p>
                )}
              </div>

              <div className="">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  รหัสผ่าน
                </label>
                <div className="relative">
                  <Input
                    type="password"
                    placeholder="ระบุรหัสผ่าน"
                    className={`${!!errors['password'] && 'border-meta-1'}`}
                    {...register('password')}
                  />

                  <span className="absolute right-4 top-4">
                    <RiLockPasswordFill fontSize={25} className="opacity-50" />
                  </span>
                </div>
                {errors.password && (
                  <p className="text-danger">
                    {errors?.password?.message as string}
                  </p>
                )}
              </div>

              <div className="">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  ยืนยันรหัสผ่าน
                </label>
                <div className="relative">
                  <Input
                    type="password"
                    placeholder="ระบุยืนยันรหัสผ่าน"
                    className={`${
                      !!errors['reTypePassword'] && 'border-meta-1'
                    }`}
                    {...register('reTypePassword', {
                      validate: (val: string) => {
                        if (watch('password') != val) {
                          return 'รหัสผ่านของคุณไม่ตรงกัน';
                        }
                      },
                    })}
                  />

                  <span className="absolute right-4 top-4">
                    <RiLockPasswordFill fontSize={25} className="opacity-50" />
                  </span>
                </div>
                {errors.reTypePassword && (
                  <p className="text-danger">
                    {errors?.reTypePassword?.message as string}
                  </p>
                )}
              </div>

              <div className="">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  อีเมล
                  <span className="text-meta-1">*</span>
                </label>
                <div className="relative">
                  <Input
                    type="email"
                    placeholder="ระบุอีเมล"
                    className={`${!!errors['email'] && 'border-meta-1'}`}
                    {...register('email', {
                      required: 'กรุณาระบุอีเมล',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'รูปแบบอีเมลไม่ถูกต้อง',
                      },
                    })}
                  />

                  <span className="absolute right-4 top-4">
                    <MdOutlineMailOutline
                      fontSize={25}
                      className="opacity-50"
                    />
                  </span>
                </div>
                {errors.email && (
                  <p className="text-danger">
                    {errors?.email?.message as string}
                  </p>
                )}
              </div>

              <div className="">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  ชื่อจริง
                  <span className="text-meta-1">*</span>
                </label>
                <Input
                  type="text"
                  placeholder="ระบุชื่อจริง"
                  className={`${!!errors['name'] && 'border-meta-1'}`}
                  {...register('name', {
                    required: 'กรุณาระบุชื่อจริง',
                  })}
                />
                {errors.name && (
                  <p className="text-danger">
                    {errors?.name?.message as string}
                  </p>
                )}
              </div>

              <div className="">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  นามสกุล
                  <span className="text-meta-1">*</span>
                </label>
                <Input
                  type="text"
                  placeholder="ระบุนามสกุล"
                  className={`${!!errors['reTypePassword'] && 'border-meta-1'}`}
                  {...register('surname', {
                    required: 'กรุณาระบุนามสกุล',
                  })}
                />
                {errors.surname && (
                  <p className="text-danger">
                    {errors?.surname?.message as string}
                  </p>
                )}
              </div>

              <div className="">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  ชื่อเล่น
                </label>
                <Input
                  type="text"
                  placeholder="ระบุชื่อเล่น"
                  {...register('nick')}
                />
              </div>

              <div className="">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  วันเกิด
                  <span className="text-meta-1">*</span>
                </label>
                <div className="relative">
                  <Input
                    className={`${
                      !!errors['dob'] && 'border-meta-1'
                    } form-datepicker`}
                    placeholder="เดือน วัน, ปี"
                    data-class="flatpickr-right"
                    {...register('dob', {
                      required: 'กรุณาระบุวันเกิด',
                    })}
                  />

                  <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.7504 2.9812H14.2879V2.36245C14.2879 2.02495 14.0066 1.71558 13.641 1.71558C13.2754 1.71558 12.9941 1.99683 12.9941 2.36245V2.9812H4.97852V2.36245C4.97852 2.02495 4.69727 1.71558 4.33164 1.71558C3.96602 1.71558 3.68477 1.99683 3.68477 2.36245V2.9812H2.25039C1.29414 2.9812 0.478516 3.7687 0.478516 4.75308V14.5406C0.478516 15.4968 1.26602 16.3125 2.25039 16.3125H15.7504C16.7066 16.3125 17.5223 15.525 17.5223 14.5406V4.72495C17.5223 3.7687 16.7066 2.9812 15.7504 2.9812ZM1.77227 8.21245H4.16289V10.9968H1.77227V8.21245ZM5.42852 8.21245H8.38164V10.9968H5.42852V8.21245ZM8.38164 12.2625V15.0187H5.42852V12.2625H8.38164V12.2625ZM9.64727 12.2625H12.6004V15.0187H9.64727V12.2625ZM9.64727 10.9968V8.21245H12.6004V10.9968H9.64727ZM13.8379 8.21245H16.2285V10.9968H13.8379V8.21245ZM2.25039 4.24683H3.71289V4.83745C3.71289 5.17495 3.99414 5.48433 4.35977 5.48433C4.72539 5.48433 5.00664 5.20308 5.00664 4.83745V4.24683H13.0504V4.83745C13.0504 5.17495 13.3316 5.48433 13.6973 5.48433C14.0629 5.48433 14.3441 5.20308 14.3441 4.83745V4.24683H15.7504C16.0316 4.24683 16.2566 4.47183 16.2566 4.75308V6.94683H1.77227V4.75308C1.77227 4.47183 1.96914 4.24683 2.25039 4.24683ZM1.77227 14.5125V12.2343H4.16289V14.9906H2.25039C1.96914 15.0187 1.77227 14.7937 1.77227 14.5125ZM15.7504 15.0187H13.8379V12.2625H16.2285V14.5406C16.2566 14.7937 16.0316 15.0187 15.7504 15.0187Z"
                        fill="#64748B"
                      />
                    </svg>
                  </div>
                </div>
                {errors.dob && (
                  <p className="text-danger">
                    {errors?.dob?.message as string}
                  </p>
                )}
              </div>

              {schools.length && (
                <>
                  {['1', '3'].includes(roleWatch) && (
                    <div className="">
                      <label className="mb-2.5 block font-medium text-black dark:text-white">
                        โรงเรียน
                        <span className="text-meta-1">*</span>
                      </label>
                      <Select
                        defaultValue={() => {
                          const school = schools.find(
                            (sch) => sch.schid === user.schid,
                          );
                          return {
                            value: school.schid,
                            label: school.sch_name,
                          };
                        }}
                        options={schools.map((sch) => ({
                          value: sch.schid,
                          label: sch.sch_name,
                        }))}
                        {...register('schid', {
                          required: 'กรุณาเลือกโรงเรียน',
                        })}
                        placeholder="เลือกโรงเรียน"
                        styles={selectStyles}
                        onChange={(e: any) => {
                          setValue('schid', e.value);
                        }}
                        className="z-9999"
                        isSearchable
                      />
                      {errors.schid && (
                        <p className="text-danger">
                          {errors?.schid?.message as string}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}

              {['1'].includes(roleWatch) && (
                <div className="">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    ระดับการศึกษา
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="relative z-20 bg-white dark:bg-form-input">
                    <SelectCommon
                      className={`${!!errors['edu_reg'] && 'border-meta-1'} `}
                      {...register('edu_reg', {
                        required: 'กรุณาเลือกระดับการศึกษา',
                      })}
                    >
                      <option value="" disabled selected>
                        เลือกระดับการศึกษา
                      </option>
                      {educations.map((item: any) => (
                        <option
                          key={item.educationid}
                          value={item.educationid}
                          className="text-body dark:text-bodydark"
                        >
                          {item.name_th}
                        </option>
                      ))}
                    </SelectCommon>

                    <span className="absolute top-1/2 right-4 z-10 -translate-y-1/2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                            fill="#637381"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                  {errors.edu_reg && (
                    <p className="text-danger">
                      {errors?.edu_reg?.message as string}
                    </p>
                  )}
                </div>
              )}

              <div className="">
                <label className="mb-2.5 block font-medium text-black dark:text-white">
                  เพศ
                  <span className="text-meta-1">*</span>
                </label>
                <div className="flex gap-4">
                  <Checkbox
                    label="ชาย"
                    id="male"
                    isChecked={gendWatch === 'male'}
                    onChange={handleGenderChanged}
                  />
                  <Checkbox
                    label="หญิง"
                    id="female"
                    isChecked={gendWatch === 'female'}
                    onChange={handleGenderChanged}
                  />
                </div>
              </div>

              {['1'].includes(roleWatch) && (
                <div className="mb-6">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    ความบกพร่องทางการอ่าน
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="flex gap-4">
                    <Checkbox
                      label="มี"
                      id="isdylx"
                      isChecked={isdylxWatch}
                      onChange={handleIsdylxChanged}
                    />
                  </div>
                </div>
              )}

              <div className="mb-5">
                <input
                  type="submit"
                  value="แก้ไขบัญชีผู้ใช้งาน"
                  className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                  disabled={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Input = tw.input`w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark`;

const SelectCommon = tw.select`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-6 outline-none transition focus:border-primary active:border-primary`;

export default Profile;
