import { useEffect, useState } from 'react';
import { HiUserGroup } from 'react-icons/hi2';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { useForm, useFieldArray } from 'react-hook-form';
import { MdOutlineCancel } from 'react-icons/md';

import {
  deleteGroupService,
  getGroupsService,
  patchGroupService,
  postGroupService,
} from '../../../services/externalv2_2';

const GroupsDialog = (props: any) => {
  const [groupsDefault, setGroupsDefault] = useState<any[]>([]);
  const { control, register, handleSubmit } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'group',
  });

  const getGroups = () => {
    getGroupsService().then((data) => {
      append(data.results.groups);
      setGroupsDefault(data.results.groups);
    });
  };

  const onSubmitHandler = async (data: any) => {
    for (const group of data.group) {
      const body = { group_name: group.group_name };
      if (group._id) {
        await patchGroupService(group._id, body);
      } else {
        await postGroupService(body);
      }
    }

    const differences = groupsDefault.filter(
      (groupi: any) =>
        !data.group.some((groupii: any) => groupi._id === groupii._id),
    ); // differences

    for (const group of differences) {
      // delete
      await deleteGroupService(group._id);
    }

    props.onClose();
  };

  useEffect(getGroups, []);

  return (
    <div
      className={` overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="relative w-auto my-6 mx-auto max-w-6xl justify-center items-center flex  "
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <form className="border-0 rounded-3xl shadow-2xl relative flex flex-col w-96 bg-white p-8 gap-4">
          {/* <!--header--> */}
          <div className="flex justify-between">
            <h3
              className="flex gap-2 text-base font-semibold leading-6 text-gray-900 text-black"
              id="modal-title"
            >
              <HiUserGroup fontSize={24} />
              Group Management
            </h3>

            <button type="button" onClick={() => props.onClose()}>
              <MdOutlineCancel fontSize={24} />
            </button>
          </div>

          {/* <!--body--> */}
          <div className="relative flex-auto">
            <div className="flex flex-col mt-4 gap-4">
              {fields.map((field, index) => (
                <div className="flex gap-4 flex-wrap">
                  <div className="w-2/3">
                    <input
                      type="text"
                      key={field.id}
                      className="border-b p-1 outline-none w-full"
                      {...register(`group.${index}.group_name`)}
                    />
                  </div>

                  <div className="flex gap-3">
                    <button
                      type="button"
                      className="flex self-end gap-2 text-red-600"
                      onClick={() => remove(index)}
                    >
                      <MdRemoveCircleOutline />
                      <div className="text-sm">ลบ</div>
                    </button>
                  </div>
                </div>
              ))}

              <button
                type="button"
                className="text-sm text-green-600 w-fit"
                onClick={() => append({ group_name: '' })}
              >
                <u>เพิ่มกลุ่ม</u>
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto hover:bg-primary hover:text-white"
            // onClick={props.onClose}
          >
            บันทึก
          </button>
        </form>
      </div>
    </div>
  );
};

export default GroupsDialog;
