import { useCallback, useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';
import { Checkbox } from '@headlessui/react';
import { BiCheckboxChecked } from 'react-icons/bi';
import { FaRegTrashAlt, FaSearch } from 'react-icons/fa';
import { GrSearchAdvanced } from 'react-icons/gr';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { IoIosAddCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  deleteStudentsService,
  getUsersService,
} from '../../../../services/externalv2_2';
import Pagination from '../../../../components/Pagination';
import { useForm } from 'react-hook-form';

const Users = () => {
  const navigate = useNavigate();

  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [userRemoveList, setUserRemoveList] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, _] = useState<number>(10);
  const [users, setUsers] = useState<any>({
    groups: [],
  });

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      search: '',
    },
  });

  const handleCheckBoxChanged = (value: string): void => {
    let userList = [...userRemoveList];
    if (value === 'all') {
      if (!isCheckAll) {
        for (const group of users.groups) {
          for (const student of group.users) {
            userList.push(student._id);
          }
        }
      } else userList = [];
      setIsCheckAll(!isCheckAll);
    } else {
      if (userList.includes(value))
        userList = userList.filter((s) => s !== value);
      else userList.push(value);
      setIsCheckAll(false);
    }
    setUserRemoveList(userList);
  };

  const onSubmitHandler = useCallback(() => {
    const values = getValues();

    getUsersService({ size: rowsPerPage, page, ...values }).then((data) => {
      setUsers(data.results);
    });
  }, [getValues]);

  const handleConfirmDeleteStudents = () => {
    withReactContent(Swal)
      .fire({
        title: 'คุณแน่ใจไหม?',
        text: 'ว่าต้องการลบข้อมูลที่เลือก',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'ใช่ฉันแน่ใจ',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'bg-primary',
          popup: 'bg-white shadow-md rounded-lg p-4',
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteStudentsService({ studentids: userRemoveList }).then((_) => {
            setUserRemoveList([]);
            setIsCheckAll(false);
            Swal.fire({
              title: 'ลบแล้ว!',
              text: 'ข้อมูลถูกลบแล้ว',
              icon: 'success',
              confirmButtonText: 'ตกลง',
            }).then((_) => getUsers());
          });
        }
      });
  };

  const getUsers = () => {
    getUsersService({ size: rowsPerPage, page }).then((data) => {
      setUsers(data.results);
    });
  };

  const getAgeInThaiFormat = (birthDate: string) => {
    const birth = dayjs(birthDate, 'DD-MM-YYYY');
    const current = dayjs();

    const years = current.diff(birth, 'year');
    birth.add(years, 'years'); // add the number of years to the birth date to calculate months

    const months = current.diff(birth, 'months') % 12;

    return `${years} ปี ${months} เดือน`;
  };

  useEffect(getUsers, [page]);

  return (
    <div className="flex flex-col gap-6 rounded-xl border border-stroke bg-white shadow-default p-8">
      <div className="text-black text-2xl font-bold">บัญชีผู้ใช้งาน</div>

      <div className="flex flex-col">
        <div className="flex flex-col gap-4 px-4 justify-between xl:flex-row">
          <div className="flex gap-4">
            <div className="self-center">
              <button
                type="button"
                onClick={() => navigate('/sornthai/admin/create-user')}
                className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF]"
              >
                <IoIosAddCircle fontSize={24} className="text-green-600" />
              </button>
            </div>

            <div className="self-center">
              <button
                type="button"
                onClick={handleConfirmDeleteStudents}
                className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF]"
                disabled={!userRemoveList.length}
              >
                <FaRegTrashAlt fontSize={24} className="text-red-600" />
              </button>
            </div>
          </div>

          <div className="flex gap-4">
            <Input
              type="text"
              placeholder="ระบุคำค้นหา ..."
              {...register('search')}
            />

            <div className="self-center">
              <button
                type="button"
                onClick={handleSubmit(onSubmitHandler)}
                className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF]"
              >
                <FaSearch fontSize={24} />
              </button>
            </div>

            <div className="self-center">
              <button
                type="button"
                className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF]"
              >
                <GrSearchAdvanced fontSize={24} />
              </button>
            </div>
          </div>
        </div>

        <div className="container mx-auto p-4 overflow-auto">
          <table className="min-w-full border-collapse border border-black table-auto overflow-scroll">
            <thead className="bg-[#808080] text-white">
              <tr>
                <Th>
                  <Checkbox
                    id="all"
                    onChange={(_) => handleCheckBoxChanged('all')}
                    checked={isCheckAll}
                    className="group block size-4 rounded border bg-white data-[checked]:bg-primary"
                  >
                    <svg
                      className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M3 8L6 11L11 3.5"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Checkbox>
                </Th>
                <Th>ดูข้อมูล</Th>
                <Th>ID</Th>
                <Th>ชื่อจริง</Th>
                <Th>นามสกุล</Th>
                <Th>เพศ</Th>
                <Th>อายุ</Th>
                <Th>ระดับการศึกษา ปัจจุบัน</Th>
                <Th>โรงเรียน</Th>
                <Th>ความบกพร่อง ทางการอ่าน</Th>
              </tr>
            </thead>
            <tbody>
              {users?.groups.map((group: any) => (
                <>
                  <tr className="bg-gray-100" key={group._id}>
                    <td colSpan={15} className="py-2 px-4 font-semibold">
                      <div className="ml-32 flex gap-4">
                        <label className="text-black">{`----- ${group.roles.name_th} -----`}</label>
                      </div>
                    </td>
                  </tr>

                  {group.users.map((user: any, s: number) => (
                    <tr className="text-center" key={s}>
                      <Td>
                        <Checkbox
                          id={user._id}
                          onChange={(_) => handleCheckBoxChanged(user._id)}
                          checked={userRemoveList.includes(user._id)}
                          className="group block size-4 rounded border bg-white data-[checked]:bg-primary"
                        >
                          <svg
                            className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M3 8L6 11L11 3.5"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Checkbox>
                      </Td>
                      <Td>
                        <div className="flex justify-center">
                          <button
                            type="button"
                            onClick={() =>
                              navigate(`/sornthai/admin/edit-user/${user._id}`)
                            }
                          >
                            <FaSearch fontSize={16} className="text-black" />
                          </button>
                        </div>
                      </Td>
                      <Td>{user.username}</Td>
                      <Td>{user.name}</Td>
                      <Td>{user.surname}</Td>
                      <Td>{user.gend === 'male' ? 'ชาย' : 'หญิง'}</Td>
                      <Td>{getAgeInThaiFormat(user.dob)}</Td>
                      <Td>{user.educations?.name_th}</Td>
                      <Td>{user?.schools?.sch_name}</Td>
                      <Td>
                        {user.isdylx && (
                          <div className="flex justify-center">
                            <BiCheckboxChecked
                              fontSize={26}
                              className="text-primary text-center"
                            />
                          </div>
                        )}
                      </Td>
                    </tr>
                  ))}
                </>
              ))}
              {!users.groups.length && (
                <tr className="text-center">
                  <Td colSpan={11}>ไม่พบข้อมูล</Td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="flex justify-center mt-2">
            <Pagination
              page={page}
              count={users.count}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Th = tw.th`py-2 px-4 font-normal border border-black`;

const Td = tw.td`py-2 px-4 font-normal border`;

const Input = tw.input`w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark`;

export default Users;
