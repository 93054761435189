const GameManual = () => {
  return (
    <main>
      <div className="mx-auto max-w-screen-2xl px-8">
        <iframe
          src="sornthai-manual.pdf"
          className="w-full h-screen"
        />
      </div>
    </main>
  );
};

export default GameManual;
