import { useEffect, useState } from 'react';
import { TbSettingsCog } from 'react-icons/tb';
import { IoIosAddCircle } from 'react-icons/io';
import { FaRegTrashAlt } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi2';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  deleteStudentsService,
  getEducationsByTeacherService,
  getStageDataAnalysisService,
  getStageDataStudentsService,
} from '../../../../services/externalv2_2';
import GroupsDialog from '../../../../components/Dialogs/Teachers/GroupsDialog';
import { useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import Checkbox from '../../../../components/Checkboxes/Checkbox';
import { useForm } from 'react-hook-form';

// configs
const bgColors: any = {
  sp2_11: 'bg-[#506CFF]',
  sp2_12: 'bg-[#FF8466]',
  sp2_21: 'bg-[#506CFF]',
  sp2_22: 'bg-[#FF8466]',
  sp2_31: 'bg-[#506CFF]',
  sp2_32: 'bg-[#FF8466]',
  sp2_41: 'bg-[#506CFF]',
  sp2_42: 'bg-[#FF8466]',
  sp2_51: 'bg-[#2A969D]',
  sp2_52: 'bg-[#35B2BB]',
  sp2_53: 'bg-[#3DD5DF]',
  all: 'bg-[#A5F0F5]',
  room: 'bg-[#26C1CC]',
};

const brColors: any = {
  sp2_11: 'border-[#506CFF]',
  sp2_12: 'border-[#FF8466]',
  sp2_21: 'border-[#506CFF]',
  sp2_22: 'border-[#FF8466]',
  sp2_31: 'border-[#506CFF]',
  sp2_32: 'border-[#FF8466]',
  sp2_41: 'border-[#506CFF]',
  sp2_42: 'border-[#FF8466]',
  sp2_51: 'border-[#2A969D]',
  sp2_52: 'border-[#35B2BB]',
  sp2_53: 'border-[#3DD5DF]',
};

const DataScope = (props: any) => {
  const bgColor = bgColors[props.type];
  return (
    <div className="flex flex-col items-center gap">
      <div
        className={`py-[2px] w-20 ${bgColor} rounded-full text-white text-center`}
      >
        {props.value || '-'}
      </div>
    </div>
  );
};

const DashboardTeacher: React.FC = () => {
  const navigate = useNavigate();

  const [groupDialogOpen, setGroupDialogOpen] = useState<boolean>(false);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [studentRemoveList, setStudentRemoveList] = useState<string[]>([]);
  const [stageDataAnalysis, setStageDataAnalysis] = useState<any>({
    playerCount: 0,
    sscores: [],
  });
  const [stageDataStudents, setStageDataStudents] = useState<any>({
    groups: [],
  });

  const [educations, setEducations] = useState<any>({
    educations: [],
  });

  const handleGroupDialogOpen = () => setGroupDialogOpen(true);
  const handleGroupDialogClose = () => setGroupDialogOpen(false);

  const { register, getValues, setValue, watch } = useForm({
    defaultValues: {
      educationid: null,
    },
  });

  const watchEducationid = watch('educationid');

  const handleConfirmDeleteStudents = () => {
    withReactContent(Swal)
      .fire({
        title: 'คุณแน่ใจไหม?',
        text: 'ว่าต้องการลบข้อมูลนักเรียนที่เลือก',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'ใช่ฉันแน่ใจ',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'bg-primary',
          popup: 'bg-white shadow-md rounded-lg p-4',
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteStudentsService({ studentids: studentRemoveList }).then((_) => {
            setStudentRemoveList([]);
            Swal.fire({
              title: 'ลบแล้ว!',
              text: 'ข้อมูลนักเรียนของคุณถูกลบแล้ว',
              icon: 'success',
              confirmButtonText: 'ตกลง',
            }).then((_) => getStageDataStudents());
          });
        }
      });
  };

  const handleCheckBoxChanged = (e: React.FormEvent<HTMLInputElement>) => {
    let students = [...studentRemoveList];
    const value = e.currentTarget.value;
    if (value === 'all') {
      if (!isCheckAll) {
        for (const group of stageDataStudents.groups) {
          for (const student of group.students) {
            students.push(student._id);
          }
        }
      } else students = [];
      setIsCheckAll(!isCheckAll);
    } else {
      if (students.includes(value))
        students = students.filter((s) => s !== value);
      else students.push(value);
      setIsCheckAll(false);
    }
    setStudentRemoveList(students);
  };

  const getStageDataAnalysis = () => {
    const { educationid } = getValues();
    if (educationid)
      getStageDataAnalysisService({ educationid }).then((data) => {
        setStageDataAnalysis(data.results);
      });
  };

  const getStageDataStudents = () => {
    const { educationid } = getValues();
    if (educationid)
      getStageDataStudentsService({ educationid }).then((data) => {
        setStageDataStudents(data.results);
      });
  };

  const getEducationsByTeacher = () => {
    getEducationsByTeacherService().then((data) => {
      setValue('educationid', data.results.educations[0].educationid);
      setEducations(data.results);
    });
  };

  useEffect(getEducationsByTeacher, []);

  useEffect(getStageDataAnalysis, [watchEducationid]);

  useEffect(getStageDataStudents, [watchEducationid]);

  return (
    <div className="flex flex-col gap-8 rounded-xl border border-stroke bg-white shadow-default p-8">
      <div className="flex flex-col gap-6">
        <div className="flex justify-between">
          <h1 className="text-black text-2xl dark:text-white font-bold self-center">
            ข้อมูลประกอบการวิเคราะห์
          </h1>

          <div className="flex justify-between gap-4">
            <div className="self-end">
              <SelectCommon {...register('educationid')}>
                {educations.educations.map((item: any) => (
                  <option
                    key={item.educationid}
                    value={item.educationid}
                    className="text-body dark:text-bodydark"
                  >
                    {item.name_th}
                  </option>
                ))}
              </SelectCommon>
            </div>

            <div className="flex flex-col border-[5px] border-[#FFC700] gap-2 text-center">
              <div className="bg-[#FFC700] px-12 text-white font-bold">
                <p>จำนวนนักเรียน</p>
                <p>ทั้งประเทศ</p>
              </div>

              <div className="text-black font-bold p-2">
                {stageDataAnalysis.playerCount.toLocaleString()}
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-4 gap-6 justify-center">
          {stageDataAnalysis.sscores.map((data: any, index: number) => {
            const bgClass = bgColors[data._id];
            const boderClass = brColors[data._id];

            return (
              <div
                key={index}
                className={`flex flex-col border-[5px] ${boderClass} gap-2`}
              >
                <div className={`${bgClass} py-2 flex flex-col gap-2`}>
                  <div className="flex justify-between">
                    <p className="text-white text-lg font-semibold">
                      {data.stageName}
                    </p>

                    <p className="text-white text-right font-semibold self-center">
                      คะแนนเต็ม: {data.max}
                    </p>
                  </div>

                  <p className="text-white text-right font-semibold">
                    ผู้เล่นทั้งหมด: {data.playerCount} คน
                  </p>
                </div>

                <div className="flex flex-col gap-2 p-2">
                  <div className="flex justify-between">
                    <p className="text-black font-semibold w-3/12 self-end">
                      Mean
                    </p>
                    <div className="flex justify-between gap-2">
                      <div>
                        <p className="text-black font-semibold text-center">
                          ประเทศ
                        </p>
                        <DataScope type="all" value={data.meanAll} />
                      </div>
                      <div>
                        <p className="text-black font-semibold text-center">
                          ห้อง
                        </p>
                        <DataScope type="room" value={data.mean} />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <p className="text-black font-semibold w-3/12 self-end">
                      Median
                    </p>
                    <div className="flex justify-between gap-2">
                      <div>
                        <DataScope type="all" value={data.medianAll} />
                      </div>
                      <div>
                        <DataScope type="room" value={data.median} />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <p className="text-black font-semibold w-3/12 self-end">
                      Mode
                    </p>
                    <div className="flex justify-between gap-2">
                      <div>
                        <DataScope type="all" value={data.modeAll} />
                      </div>
                      <div>
                        <DataScope type="room" value={data.mode} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="text-black text-2xl dark:text-white font-bold">
          ข้อมูลนักเรียนรายบุคคล
        </h1>

        <div className="flex gap-4 px-4">
          <button
            onClick={() =>
              navigate('/sornthai/dashboard-teacher/create-student')
            }
            className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF]"
          >
            <IoIosAddCircle fontSize={24} className="text-green-600" />
          </button>
          <button
            onClick={handleGroupDialogOpen}
            className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF]"
          >
            <HiUserGroup fontSize={24} />
          </button>
          <button
            onClick={handleConfirmDeleteStudents}
            className="rounded-full border border-[#D9D9D9] bg-[#D9D9D9] p-2 hover:bg-[#FFFFFF]"
            disabled={!studentRemoveList.length}
          >
            <FaRegTrashAlt fontSize={24} className="text-red-600" />
          </button>
        </div>

        <div className="container mx-auto p-4 overflow-auto">
          <table className="min-w-full border-collapse border border-black table-auto overflow-scroll">
            <thead className="bg-[#808080] text-white font-normal">
              <tr>
                <Th rowSpan={2}>
                  <Checkbox
                    id="all"
                    onChange={handleCheckBoxChanged}
                    isChecked={isCheckAll}
                  />
                </Th>
                <Th rowSpan={2}>ชื่อจริง</Th>
                <Th rowSpan={2}>นามสกุล</Th>
                <Th rowSpan={2}>ชื่อเล่น</Th>
                <Th colSpan={8}>คะแนนเกมสระ</Th>
                <Th colSpan={3}>คะแนนเกมวรรณยุกต์</Th>
                <Th rowSpan={2}></Th>
              </tr>
              <tr>
                <Th>1.1</Th>
                <Th>1.2</Th>
                <Th>2.1</Th>
                <Th>2.2</Th>
                <Th>3.1</Th>
                <Th>3.2</Th>
                <Th>4.1</Th>
                <Th>4.2</Th>

                <Th>1</Th>
                <Th>2</Th>
                <Th>3</Th>
              </tr>
            </thead>
            <tbody>
              {stageDataStudents.groups.map((data: any, p: number) => (
                <>
                  <tr className="bg-gray-100" key={p}>
                    <td colSpan={15} className="py-2 px-4 font-semibold">
                      <div className="ml-12 flex gap-4">
                        {data._id && (
                          <button className="border rounded-full p-1">
                            <TbSettingsCog className="self-center" />
                          </button>
                        )}
                        <label className="text-black">
                          {data._id
                            ? `----- ${data.group[0].group_name} -----`
                            : `----- ยังไม่ได้รับการจัดกลุ่ม -----`}
                        </label>
                      </div>
                    </td>
                  </tr>

                  {data.students.map((student: any, s: number) => (
                    <tr className="text-center" key={s}>
                      <Td>
                        <Checkbox
                          id={student._id}
                          onChange={handleCheckBoxChanged}
                          isChecked={studentRemoveList.includes(student._id)}
                        />
                      </Td>
                      <Td>{student.name}</Td>
                      <Td>{student.surname}</Td>
                      <Td>{student.nick || '-'}</Td>
                      {student.sscores.map((sscore: any, t: number) => (
                        <Td key={`score-${t}`}>{sscore.score}</Td>
                      ))}
                      {11 - student.sscores.length !== 0 && (
                        <Td colSpan={11 - student.sscores.length} key={`empty`}>
                          ยังไม่มีคะแนน
                        </Td>
                      )}

                      <Td className="text-center">
                        <button
                          type="button"
                          className="border rounded-full p-1"
                          onClick={() =>
                            navigate(
                              `/sornthai/dashboard-teacher/user-data-stages/${student._id}`,
                            )
                          }
                        >
                          <TbSettingsCog className="self-center" />
                        </button>
                      </Td>
                    </tr>
                  ))}
                </>
              ))}
              {!stageDataStudents.groups.length && (
                <tr className="text-center">
                  <Td colSpan={16}>ไม่พบข้อมูล</Td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* dialog */}
      {groupDialogOpen && <GroupsDialog onClose={handleGroupDialogClose} />}
    </div>
  );
};

const Th = tw.th`py-2 px-4 border border-black font-normal`;
const Td = tw.td`py-2 px-4 border`;
const SelectCommon = tw.select`relative z-20 w-full rounded border border-stroke bg-transparent py-3 px-6 outline-none transition focus:border-primary active:border-primary`;

export default DashboardTeacher;
