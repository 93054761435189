import { Navigate, Outlet } from 'react-router-dom';
import useUser from '../../hooks/user';
import { useEffect, useState } from 'react';

const ProtectedRoute = ({ roles = [] }: any) => {
  const user = useUser('token');
  const [path, setPath] = useState('');

  useEffect(() => {
    switch (user.role) {
      case 0:
      case 1:
      case 2:
        setPath('/sornthai/dashboard-parent');
        break;

      case 3:
        setPath('/sornthai/dashboard-teacher');
        break;

      case 4:
        setPath('/sornthai/dashboard-researcher');
        break;

      case 5:
      case 6:
        setPath('/sornthai/admin/users');
        break;

      default:
        break;
    }
  }, []);

  return user ? (
    roles && !roles.includes(user.role) ? (
      <Navigate to={path} />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
