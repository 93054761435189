import { useState } from 'react';
import { jwtDecode } from 'jwt-decode';

function useUser(key: string) {
  // State to store our value
  // Pass  initial state function to useState so logic is only executed once
  const [storedValue, _] = useState<any>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? jwtDecode(JSON.parse(item)) : {};
    } catch (error) {
      // If error also return initialValue
      return {};
    }
  });

  return storedValue;
}

export default useUser;
