const Checkbox = (props: any) => {
  return (
    <div>
      <label
        htmlFor={props.id}
        className="flex cursor-pointer select-none items-center"
      >
        <div className="relative">
          <input
            type="checkbox"
            id={props.id}
            value={props.id}
            className="sr-only"
            onChange={props.onChange}
          />
          <div
            className={`${
              props.label && 'mr-4'
            } flex h-5 w-5  bg-white items-center justify-center rounded border ${
              props.isChecked && 'border-primary bg-gray dark:bg-transparent'
            }`}
          >
            <span
              className={`h-2.5 w-2.5 rounded-sm ${
                props.isChecked && 'bg-primary'
              }`}
            ></span>
          </div>
        </div>
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
